import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      Phone: "Phone",
      incorrect: "Incorrect",
      fio: "Full Name",
      connect: "Connect",
      legal: "Legal entities",
      companyName: "Name of organization",
      futures: "Futures",
      templates: "Payment Templates",
      createTemplate: "Сreate a template",
      nameOfService: "Service name",
      tryAgain: "Try again",
      partners: "Our Partners",
      forBank:
        "This ID must be specified in the payment reference when making a bank transfer",
      answerTheQuestions: "Please answer the questions",
      MainHeroTitle: "Simple and easy access to cryptocurrencies",
      MainHeroTitle2: "Pay all utility bills by crypto",
      MainHeroDescription2: "Withdraw cryptocurrency to your bank card",
      binancePayTitle:
        "You can go to the payment page by scanning the QR-Code or by pressing the button",
      MainHeroDescription: "Fast and secure way to buy or exchange",
      type: "Type",
      license: "License",
      condition: "Condition",
      side: "Side",
      youNeedVerification:
        "To conduct any transactions, you need to be verified",
      pair: "Pair",
      MainHeroDescription3:
        "Make deals and exchange cryptocurrency with other users with the lowest commissions",
      MainHeroTitle4:
        "NETEX LAND is your way into the world of cryptocurrencies",
      MainHeroDescription4:
        "Register your wallet, top up your balance and start opening cases",
      change: "Change",
      AmountInCurrency: "Amount in currency",

      SatisfiedClients: "100+ Satisfied customers",
      Yees: "Yes",
      Noo: "No",
      BuyCurrencies: "Buy crypto",
      PaymentSystemShort: "Payment system",
      paymentCheck: "Receipt of payment",
      mapLocation: "Map location",
      bankCardNumber: " Bank Card number",
      SliderButton2: "Pay services",
      p2pButon1: "Create deal",
      p2pButon2: "All deals",
      bankCard: "Bank Card",
      NetexLandButton: "NETEX Land",
      QuestionButton: "How does it work?",
      ViewAllCourses: "all courses",
      benefits1: "Best rates by market",
      benefits2: "Transparent commission from 0.5%",
      rate: "Rate",
      benefits3: "5-30 minute transactions",
      benefits4: "High limits on exchanges",
      benefits5: "Live chat support 24/7",
      ReadReviews: "Read users' reviews",
      Reviews: "Reviews",
      leaveReview: "Leave review",
      BuyDescription: `It's simple and easy! Select the currency you want to get, then enter the amount to exchange, then click "Buy" button. You will be shown the current rate and the reserve of our exchanger
      exchange point, and you'll see the fields you'll have to complete:
      wallet numbers, card numbers and e-mail.`,
      Currency: "Currency",
      SelectCurrencyDeal: "Select currency",
      USDT: "Amount in USDT",
      SelectCurrency: "Select the currency you want to buy...",
      BuyBankCard: "Buy currency by bank card",
      "WeAccept:": "We accept:",
      EnterAmount: "Enter the amount...",
      StaticTitle: "Statistics for 24 hours",
      allNewsTitle: "All company news",
      Static1: "Completed transactions",
      Completed: "Completed",
      Static2: "Total open accounts",
      Static3: "Turnover per day",
      Static4: "Reserves",
      App: "Netex App",
      AppText: "Always at your fingertips. Ready to download.",
      NetexText: "Accepting payments via cryptocurrencies",
      LinkToSite: "Visit the website",
      NewsTitle: "Netex news",
      ReadNews: "Read all news",
      About: "About",
      NavHowStarted: "How it works?",
      Contact: "Contact",
      News: "News",
      LogIn: "Log in",
      Registration: "Registration",
      LogInTitle: "Log in to your personal account",
      LogInDontAccount: `No account yet? Sign up`,
      SignUp: "Sign up",
      Password: "Password",
      ForgotPassword: "Forgot password?",
      SignInTo: "Sign in to your account",
      CreateAccount: "Create an account",
      HaveAnAccount: "Already have an account?",
      ResetTitle: "Restore account",
      quantity: "Quantity",
      stop: "Stop",
      ResetDesc: `We'll email you a link to change your password`,
      ActivationAuthentication: "Account activation",
      EnterActivationAuthentication: "Enter code for 2FA authentication",
      ActivationCode: "Enter code",
      EnterActivationCode: "Enter code",
      LabelRepeatPassword: "Create a password",
      LabelName: "Enter a name",
      activationDescr:
        "An email was sent to you with a confirmation code, enter it to activate your account.",
      LabelLastName: "Enter last name",
      Name: "Name",
      id: "ID",
      explorer: "Explorer",
      Login: "Login",
      LastName: "Last name",
      LabelNewPassword: "Enter a new password",
      LabelPhone: "Enter phone number",
      LabelRepeatNewPassword: "Confirm password",
      TermsOfUseButton:
        'By clicking on the "Register" button, you accept the terms of the ',
      TermsOfUse: "Public offer",
      LabelOldPassword: "Enter the old password",
      LabelSecurityCode: "Enter security code",
      NewPassword: "New password",
      OldPassword: "Old password",
      SecurityCode: "Security code",
      FullName: "Surname, first name, patronymic",
      DateOfBirth: "Date of birth",
      PassportNumber: "Passport number",
      TIN: "TIN",
      AddressOfResidence: "Address of residence",
      Address: "Address",
      IdentityDocument: `Document,
      Identity document`,
      ResidenceAddress: `Photo with passport in hand`,
      SelectFile: "Select file",
      LabelEmail: "Enter your email address",
      LabelPassword: "Think of a password",
      LabelPasswordYou: "Enter your password",
      LabelLogin: "Think of a login",

      OurAddress: "Our address",
      addressInfo:
        "Igemberdieva 1A Business Center Aurora 4th floor 406 office",
      KyrgyzRepublic: "(Kyrgyz Republic, Bishkek, 720005)",
      Country: "Country",
      ChooseCountry: "Choose a country",
      enable: "Enable",
      disable: "Disable",
      City: "City",
      Aress: "Address",
      PhoneNumber: "Phone",
      toLinkPhoneNumber: "To link the phone number, pass",
      Save: "Save",
      NotSpecified: "Not specified",
      LogOut: "Log out",
      select: "select",
      Aboutp1: `Netex is your personal exchange service. Netex allows you to make
      exchange of e-currencies in any place. You can make exchanges with Netex from any device:
      phone, tablet or computer.`,
      Aboutp2: `Netex is a system based on state-of-the-art software
      Netex is a system based on modern software with a full set of necessary functions
      for convenient and secure conversion of the most
      common types of e-money.`,
      Aboutp3: `Our e-currency exchanger is designed for ones who want to quickly,
      securely and at a favorable exchange rate to exchange such types of
      e-currencies such as: Perfect Money, Payeer, AdvCash, Qiwi, Yandex,
      cryptocurrencies Bitcoin, Bitcoin Cash, Ethereum, Litecoin and others.`,
      Aboutp4: `The advantage of the Netex service is not only its reliability, but also the attractiveness of exchange rates for almost all exchange directions.
      exchange rates. The site has 24-hour online chat, where the
      any questions are answered by the support service.
      In addition, consultants can be contacted by phone.`,
      Aboutp5: `Reputation is paramount! The main credo of the service is absolute
      transparency and honesty. You can be sure of the safety
      of your funds when you make an exchange at Netex.`,
      FAQTitle: "Frequently Asked Questions (FAQ)",
      main: "Home",
      topUp: "Replenish",
      withdraw: "Withdraw",
      transactions: "Transfers",
      operations: "Operations",

      NetworkCommission: "Network commission",

      exchangeRates: "Currency rate",

      SendLink: "Send link",
      YourBalance: "Your balance:",
      Settings: "Settings",

      p2pСonfirmation: "Attach a photo of the receipt",
      Сheque: "Сheque",
      PaymentDetailsWarningText:
        "Please be careful when entering your payment details.",
      VerificationWarningTextp1:
        "Account identification is necessary to prevent fraud, anti-money laundering and terrorist financing.",
      VerificationWarningTextp2: `- Upload a scanned copy or photo of your domestic or international passport, driving licence, military ID in the "identity document" field.`,
      VerificationWarningTextp3: `- In the "proof of address" field, upload a scanned copy or photo of your passport page (only if you uploaded your internal passport as proof of identity), or any official document with your stamp and residence address on which your details appear.`,
      VerificationWarningTextp4: `Please follow the instructions in the verification widget below.`,
      PersonalData: "Personal data",
      SecurityTitle: "Two-factor authorisation",
      onlyLatine: "Latin letters only, numbers",
      SecurityText: "This is an extra layer of protection for your account.",
      SecurityDescription:
        "Download the Google Authenticator app or any similar app from the Play Market or App Store. Then, in the mobile app, scan this QR code. If your mobile app doesn't support QR codes, enter the following code:",
      limits: "Limits on preferential rate",

      replenishment: "Remplenishment",

      sale: "Sell",
      buyEMoney: "Buy cryptocurrency",
      sellEMoney: "Sell cryptocurrency",
      purchase: "Purchase",
      withdrawal: "Withdrawal",
      TopUpBalance: "Replenish balance",

      moneyTransaction: "Transfer",
      sendFunds: "Send funds",
      sendFundsDescr: "Send funds to other Netex users",
      getFunds: "Receive funds",
      getFundsDescr: "Receive funds from other Netex users",
      showAllFunds: "Show all funds received",
      SeeAll: "See all",
      seeInfo: "See info",
      userLogin: "User email",
      enterUserLogin: "Enter user email",
      protectionCode: "Protection code",
      auth: "Auth",
      cashout: "Cashout",
      transfer: "Transfer",
      enterProtectionCode: "Enter protection code",
      currencyAmount: "Currency and amount for the transfer",
      availableAmount: "Available amount:  ",
      continue: "Continue",
      getFundsSubtitle:
        "A request to send funds has been sent to your account, enter the protection code to receive funds",
      allFundsReceived: "All funds received",
      newFunds: "New",
      oldFunds: "Old",
      fromWhom: "From whom",
      amount: "Amount",
      acceptFunds: "Accept",
      acceptedFunds: "Accepted",

      operationHistory: "Operations history",
      allOperations: "All history",

      YourWallets: "Your balances",

      ThePeriodFrom: "The period from",
      PeriodTo: "Period to",

      incomes: "Incomes",
      expenses: "Expenses",
      state: "State",

      Withdraw: "Withdraw",
      deposit: "Deposit",

      phoneNumber: "Phone",
      email: "Email",
      LastEntrance: "Last entrance",
      dateOfRegistration: "Registration date",
      buy: "Buy",
      payments: "Payment for services",
      paymentsBP: "Payment for services via Binance Pay",
      interiorTransfer: "Internal transfer",
      AccountHistory: "Account history",
      date: "Date",
      action: "Action",
      ipAddress: "IP Address",
      browser: "Browser",

      cryptocurrencies: "Cryptocurrencies",
      fiat: "Fiat",
      terminals: "Terminals",
      cash: "Cash",
      replenishmentSubtitle:
        " When depositing by any of the methods, your payment amount will be converted into USDT at the exchange rate of our top-up currency website. Your balance is displayed in USDT (Tether).",
      paymentByCard: "Payment by card",
      topUpVia: "Replenish via",
      commissions: "Commissions",
      enterTheAmount: "Enter the amount in",
      paymentСonfirmation: "Payment confirmation",
      amountToBeCredited: "Amount to be credited",
      success: "Successfuly enabled",
      successDisable: "Successfuly disabled",
      amountToPay: "Amount to pay",
      ourCommission: "Our commission",
      additionalCommission: "Additional commission",
      systemCommission: "System commission",
      note: "Note",
      confirm: "Сonfirm",
      depositAddress: "deposit address",
      DialogCryptoSubtitle:
        "If you send cryptocurrency to an incorrect address (for example, bitcoin to a Bitcoin Cash address), the cryptocurrency will be lost.",
      adress: "adress",
      copied: "Copied",
      copy: "Copy",
      ToPayThroughTheTerminal: "To pay through the terminal",
      clear: "Clear",
      ForPayment: "For payment",
      YouNeedToContactUsByWhatsApp: "You need to contact us by WhatsApp",

      WithdrawFunds: "Withdraw funds",
      enterNumber: "Enter number of",
      DueDate: "Due date",
      Instantly: "Instantly",
      f2a: "Two-Factor Authentication",
      f2aSubtitle: "Go to your account and enter the code you received",
      MinAmount: "Minimum amount",
      survey: "Survey",
      of: "of",
      OurCommission: "Our commission",
      SystemFee: "System fee",
      AmountDispatch: "Amount of dispatch",
      AmountToCredited: "Amount to be credited",
      WriteOffAmount: "Write-off amount",
      Continue: "Continue",
      network: "Network",
      number: "Number",
      balance: "Balance",
      atRate: "At the rate",

      WithdrawFundsFrom: "Withdraw funds from",
      withdrawWallet: "wallet",

      WorkingTime: "Working time",
      WorkingTimeInfo: "Mon-Fri 10:00 - 19:00",
      agreeText: "I have read and agree with the above information ",
      warningTextP2P: `To avoid becoming a victim of scammers, never transfer cryptocurrency before actually receiving payment! Don't trust anyone who pretends to be a customer service and convinces
      you to complete the transaction before you receive the payment - they are scammers. Once the seller confirms the order and transfers the assets to the buyer, the transaction is considered completed and cannot be disputed. Netex does not take any responsibility for transactions made outside of the platform.`,
      inProgress: "in progress",
      WarningParticipantSent:
        "You have successfully joined the transaction to transfer/refill the bank card. Save a photo of the check/screenshot of the transfer and click send.",
      WarningOwnerSent:
        "Save a photo of the check/screenshot of the transfer and click send.",
      EnterYourPersonal: "Enter your personal information",
      Retrieved: "Retrieved",
      ConfirmCash: `Carefully check if you have received payment. After you click "Received", the funds will be transferred to the other participant's account irrevocably and the transaction will be completed`,
      deleteDeal: "Are you sure you want to delete.",
      participantName: "Psdarticipant name",
      participantPhone: "Participant phone",
      meeting: "Personal meeting",
      card2: "bank card (fiat to crypto)",
      warningСheque: `Verify that the money has been deposited into your bank account. If you click the (confirm) button, 
      the funds will be transferred irrevocably.`,
      bankNameLabel: "Write the name of the bank",
      bankName: "Bank name",
      Joined: "Successfully joined",
      Send: "Send",
      Join: "Join",
      More: "More details",
      СhequepaymentByCard: "Сheque Payment by card",
      CreaterDeal: "The creator of this deal",
      hours: "hours",
      myDeals: "My deals",
      Deals: "Deals",
      CreateDeal: "Create a deal",
      AmountFrom: "Amount from",
      AmountTo: "Amount to",
      Search: "Search",
      SearchCoins: "Search coins...",
      ListDeals: "List of deals",
      Offers: "Offers",
      ForAmount: "For the amount of",
      GiveBack: "Give",
      GetIt: "Get",
      order: "Активные ордера",
      historyOrder: "Order history",
      orders: "Orders",
      PaymentService: "Payment service",
      User: "User",
      sell: "Sell",
      aboutusTXT: `Netex.kg - the first and the most reliable service in Kyrgyzstan 
for transactions with cryptocurrency! 

Fast withdrawals to e-wallets, bank cards and bank accounts. 
Instant purchase of USDT through terminals and O!Money!
Channel merchant partner of Binance Pay - get soms to your bank directly from the Binance exchange. 

Netex allows you to buy or sell cryptocurrencies easily and conveniently. 

Use any device - phone, tablet or computer.

Reputation above all! The main credo of the service is absolute transparency and honesty. 
You can be sure that your funds are safe when exchanging with Netex.

The company's activity is regulated by the Financial Market Regulation and Supervision Service 
under the Ministry of Economy and Commerce of the Kyrgyz Republic, 
Virtual Asset Exchange Operator License #03 dated 15.11.2022.

tel: +996 508 24 1111
E-mail: business@netex.kg

Netex LLC.
Company number: 189262-3301-OOO
TIN: 01402202010302
Legal address: 720005; Kyrgyz Republic, BISHKEK, Lenin district, 106 Suvanberdiev str., sq. 22.
Actual address: 720005; Kyrgyz Republic, BISHKEK, Pervomaisky district, 1A Igemberdiev Street, Aurora Business Center, 8th floor, 801 office.`,
      TypeDeal: "Type of transaction",
      newDeal: "A new deal",
      deal: "Deal",
      AmountReceived: "Amount received",
      EnterAmountReceived: "Enter the receipt amount",
      EnterTypeDeal: "Select the type of transaction",
      CoinType: "Coin Type",
      SelecCoinType: "Select coin type",
      ReceiptWallet: "Bank card number",
      ChooseWallet: "Choose a wallet",
      AmountShipment: "Amount of shipment (Fiat)",
      EnterAmountShipment: "Enter the amount of the shipment",
      Commentary: "Commentary",
      WriteComment: "Write a comment",
      Preview: "Preview",
      TranslationMethod: "Translation method",
      Cryptocurrency: "Cryptocurrency",

      coinTitle: "Name",
      price: "Price",
      dynamic: "Dynamic",
      market: "Market",
      personalAccount: "Personal Account",
      personalData: "Personal Information",

      SiteNavigation: "Site Navigation",
      ContactUs: "Contact Us",
      Commission: "Commission",

      OperationType: "Operation type",
      DateTime: "Date and time",
      Amount: "Amount",
      PaymentSystem: "Payment system",
      Props: "Props",
      ReceiptNumber: "Receipt number",
      unavailable: "The service is temporarily unavailable",
      AmountToBeDebited: "Amount to be debited",
      AmountReceivable: "Amount receivable",

      p2pDealsTitle: "P2P Deals",
      exchange: "Exchange",
      allDeals: "All deals",
      updatedIn: "Updated in",
      howToStart: "How to start ?",

      sitePolicy: "Site Policy",

      usageTitle: "Public Offer",
      usageSubtitle:
        "Agreement on the provision of services by the service Netex.kg",
      usageArticle1: "1. General provisions",
      usageArticle2: "2. Terms and definitions used in the Agreement",
      usageArticle3: "3. Subject of the agreement",
      usageArticle4:
        "4. The procedure for the provision of services by the Service",
      usageArticle5: "5. Service cost",
      usageArticle6: "6. E-currency exchange",
      usageArticle7:
        "7. The procedure for the provision of services by the Service",
      usageArticle8: "8. Responsibility of the Parties",
      usageArticle9: "9. Other provisions",

      usageArticle1Сlause1: `This agreement (hereinafter referred to as the Agreement) describes the terms and conditions on the basis of which the services of the Netex multicurrency exchange service are provided.kg and is an official written public offer addressed to individuals (hereinafter referred to as the User) to conclude an Agreement on the provision of services by the Netex service.kg on the conditions set out below. Before using the services of the Netex service.kg, the User is obliged to familiarize himself in full with the terms of the "Agreement on the provision of services by the service Netex.kg ". Using the services of the Netex service.this is possible only if the User accepts all the terms of the Agreement. The current version of the Agreement is available for public access on the service's website Net.kg (www.Netex.kg ).`,

      usageArticle2Сlause1:
        "Netex service.kg is a trademark and commercial designation of a system for providing Internet services for the exchange of electronic currencies.",
      usageArticle2Сlause2: "The Service's website – www.netex.kg .",
      usageArticle2Сlause3:
        "User — any individual who wishes to use the services of the Netex service.kg and has carried out the acceptance of the Agreement in accordance with its terms.",
      usageArticle2Сlause4:
        "A payment system is a software product created by a third party, which is a mechanism for the implementation of accounting for monetary and/or other obligations, payment for goods and services on the Internet, as well as the organization of mutual settlements between its users.",
      usageArticle2Сlause6:
        "The client of the payment system is a person who has concluded an agreement with the relevant payment system for the acquisition of property rights of claim to it, measured in conventional units accepted in the relevant payment system.",
      usageArticle2Сlause7:
        "The application is an expression of the User's intention to use one of the services offered by the Netex Service.kg, by filling out an electronic form via the Service's website, on the terms described in the Agreement and specified in the parameters of this Application.",
      usageArticle2Сlause8:
        "The source currency is the electronic currency that the User wants to sell or exchange.",
      usageArticle2Сlause9:
        "Source account – the wallet number or any other designation of the User's account in the Payment System from which the Source Currency was sent.",
      usageArticle2Сlause10:
        "The resulting currency is the electronic currency that the User receives as a result of the sale or exchange of the Original currency.",
      usageArticle2Сlause11:
        "The resulting account is the wallet number or any other designation of the User's account in the Payment System to which the Resulting Currency will be sent",
      usageArticle2Сlause12:
        "Reserve currency - available to the Netex Service.kg, at the time of creation of the Application, the volume of a certain Electronic currency.",
      usageArticle2Сlause13:
        "Currency exchange is the exchange of the electronic currency of one payment system for the electronic currency of another payment system.",
      usageArticle2Сlause14:
        "The exchange rate is the value ratio of two electronic currencies when they are exchanged.",
      usageArticle2Сlause15:
        "Hacker is a qualified computer hacker, an attacker specializing in finding and using unauthorized access to computer networks or other computerized equipment for illegally obtaining information, extracting benefits, causing damage.",

      usageArticle3Сlause1:
        "3.1. The subject of this Agreement is the provision of the Netex Service to the User.kg electronic currency exchange services.",

      usageArticle4Сlause1:
        "4.1. Ordering Netex services.registration is carried out by the User by sending an Application through the Service's website.",
      usageArticle4Сlause2:
        "4.2. Managing the transaction process or receiving information about the progress of the transaction by the User is carried out using the appropriate user interface located on the Service's website.",
      usageArticle4Сlause3:
        "4.3. Service Net.kg executes Applications on an irrevocable basis in accordance with the operating conditions of the relevant payment systems.",
      usageArticle4Сlause4:
        "4.5. Net Service.kg is not a party to the agreement between the Payment System and the Client of the Payment System and in no case is responsible for the actions of the Payment System and its Client. The rights and obligations of the payment system and its Client are governed by the terms of service of the respective Payment Systems.",
      usageArticle4Сlause5:
        "4.6. Netex service.kg does not require certification that the sender and recipient of the funds involved in the Operation are the same person, the Netex Service.kg is not a party in the relationship between the sender and the recipient of funds or electronic currency.",
      usageArticle4Сlause6:
        "4.7. Net service.kg does not verify the competence and legality of the User's possession of electronic currencies and/or funds involved in a particular Transaction.",
      usageArticle4Сlause7:
        "4.8. Using the services of the Service Net.kg , The User confirms that he legally owns and disposes of the funds and electronic currency involved in the corresponding Payment.",
      usageArticle4Сlause8:
        "4.9. The User undertakes to independently calculate and pay all taxes required by the tax legislation of the User's location.",
      usageArticle4Сlause9:
        "4.10. Taking care of the quality of the services provided to Users, the Service Net.kg undertakes to perform all actions within the framework of this Agreement as quickly as possible.",

      usageArticle5Сlause1:
        "5.1. The cost of the services of the Service is set by the management of the Service and published on the Internet site of the Service.",
      usageArticle5Сlause2:
        "5.2. The Service has the right to independently change the exchange rates of electronic currencies and the fees charged at any time unilaterally, which notifies the Service Users by pre-posting information about these changes on the Service's website.",
      usageArticle5Сlause3:
        "5.3. The Application created by the User on the Service's website indicates the Exchange Rate, the amount of the commission charged by the relevant Payment system for conducting the Operation, the amount of the Netex Service remuneration.kg, as well as the total amount of transferred funds or electronic currency.",
      usageArticle5Сlause4:
        "5.4. Netex service.kg charges the cost of its remuneration at the time of the relevant Operation. The Service's remuneration is deducted from the amount of the Resulting Currency.",

      usageArticle6Сlause1:
        "6.1. By making an Application, the User instructs, and the Netex Service.kg, on its own behalf and at the User's expense, performs actions to exchange the Electronic currency of one Payment System (the Source Currency) for the Electronic currency of another Payment System (the Resulting currency) selected by the User.",
      usageArticle6Сlause2:
        "6.2. The User undertakes to transfer (transfer) The original currency, in the amount specified in the Application, and the Netex Service.kg, after receiving the corresponding Electronic Currency, undertakes to transfer (transfer) To the user, the resulting currency calculated at the Exchange rate and in accordance with the tariffs of the service.",
      usageArticle6Сlause3: `6.3. The amount of Netex Service remuneration.kg is reflected in the Application and confirmed by the User by clicking the "Next" button on one of the pages of the user interface when making an application.`,
      usageArticle6Сlause4:
        "6.4. Obligation of the Netex Service.kg by transfer (transfer) The User is deemed to have executed the Electronic Currency at the time of debiting the Electronic Currency in the corresponding Payment system from the Netex Service account.kg, which is recorded in the transaction history of the corresponding Payment system.",
      usageArticle6Сlause5:
        "6.5. Service Net.kg the User has the right to cancel the Application created by the User if the funds in the amount of the Original Currency have not been credited to the service account within 20 minutes.",
      usageArticle6Сlause6:
        "6.6. Service Net.kg has the right to suspend the operation and withhold the User's funds in order to prevent fraudulent and other actions that may cause financial and reputational losses for the Service or the User.",
      usageArticle6Сlause7:
        "6.7. Netex service.kg has the right to set financial and quantitative limits on Transactions. Information about the limits is indicated on the Service's website.",

      usageArticle7Сlause1:
        "7.1. This Agreement is considered concluded on the terms of a public offer accepted by the User during the submission of the Application.",
      usageArticle7Сlause2:
        "7.2. The Public offer is recognized as displayed by the Service Net.kg information about the parameters and conditions of the Application.",
      usageArticle7Сlause3:
        "7.3. Acceptance of the public offer is recognized as the User performing actions to complete the formation of the Application, confirming his intention to use the services of the Netex Service.kg on the terms described in these Agreements and specified in the Application.",
      usageArticle7Сlause4:
        "7.4. The date and time of acceptance, as well as the parameters of the Application conditions are fixed by the Net Service.kg automatically at the time of completion of the application",
      usageArticle7Сlause5:
        "7.5. The Agreement comes into force from the moment of completion of the Application by the User. The User has the right to refuse to perform an operation on the Request before paying for the Operation.",

      usageArticle8Сlause1:
        "8.1. Net service.kg is liable to the User in an amount not exceeding the amount of funds or electronic currency entrusted by the User.",
      usageArticle8Сlause2:
        "8.2. Net Service.kg is not responsible for malfunctions, errors and failures in the operation of software and/or hardware that ensure the functioning of Netex services.kg that arose for reasons beyond the control of the Netex Service.kg, as well as related User losses.",
      usageArticle8Сlause3:
        "8.3 Netex Service.kg provides services only for the exchange, purchase and sale of electronic currencies. Net.kg in no way accepts payment in favor of any third parties, and also prohibits exchange for wallets / accounts that do not belong to the user. Net.kg does not enter into any partnership relations, does not conclude any contracts with recipients of payments for its goods or services, and is categorically against such relations. Net.kg cannot be used as an intermediate service for mutual settlements between buyer and seller (customer and contractor, etc.).",
      usageArticle8Сlause4:
        "8.4. Netex service.kg is not responsible for the User's losses resulting from the illegal actions of third parties.",
      usageArticle8Сlause5:
        "8.5. The User is solely responsible for the accuracy of the information provided by him when filling out the Application. If the User has not specified or incorrectly specified the data, the Net Service.kg is not responsible for the User's losses incurred as a result of an error.",
      usageArticle8Сlause6:
        "8.6. Information on the Operation is stored in the database of the service and is the primary source to which the Parties to the agreement are guided in disputable situations.",
      usageArticle8Сlause7:
        "8.7. The Parties are released from liability for full or partial non-fulfillment of their obligations under the Agreement, if such was the result of force majeure circumstances that arose after the entry into force of the Agreement, as a result of extraordinary events that could not have been foreseen and prevented by reasonable measures.",
      usageArticle8Сlause8:
        "8.8. In other cases of non-fulfillment or improper fulfillment of their obligations under the Agreement, the Parties are liable in accordance with the legislation of the Kyrgyz Republic, taking into account the terms of the Agreement.",

      usageArticle9Сlause1:
        "9.1. Service Net.kg The Company has the right to unilaterally amend the Agreements by publishing changes on the System's Website. The amendments come into force from the moment of publication, unless another date for the entry into force of the amendments is additionally determined at the time of their publication.",
      usageArticle9Сlause2:
        "9.2. Netex service.kg has the right to refuse the user to make an application without explaining the reasons for the refusal.",
      usageArticle9Сlause3:
        "9.3. Service Net.kg in case of suspicious actions during the application process by the user, in order to avoid damage from Hacker attacks, it has the right to suspend the execution of such operations until the reasons for these actions are clarified.",
      usageArticle9Сlause4:
        "9.4. Netex service.kg has the right to refuse to perform the operation of exchanging, buying and selling electronic currencies if the transfer of the Original currency to the service account was made without making an application using user interfaces on the service's website. The electronic currency transferred to the service's accounts, without making an application using user interfaces on the service's website, can be returned to the user upon request, taking into account the deduction of the Payment System commission, if any.",
      usageArticle9Сlause5:
        "9.5. Service Net.kg has the right to send the User to the specified e-mail information about the status of the exchange process, because this is an integral part of the process of successful completion of the exchange.",
      usageArticle9Сlause6:
        "9.6. All disputes and disagreements that have arisen or may arise from this Agreement are subject to settlement through negotiations on the basis of a written application of the User. Service Net.kg after receiving a claim from the User, he is obliged to satisfy the claims stated in the claim within 15 (fifteen) days or send a reasoned refusal to the User. All necessary documents must be attached to the answer. If the dispute that has arisen is not resolved in a claim procedure within 60 (sixty) days, either Party has the right to apply for dispute resolution to the court at the User's location.",

      usageСonfirm:
        "The User confirms that he is familiar with all the provisions of this Agreement and unconditionally accepts them.",
      usagePublicDate: "The Agreement was published on December 16, 2019.",

      privacyPolicy: "Privacy policy",
      privacyPolicyText1: "Effective date: December 16, 2019",
      privacyPolicyText2: "Netex.kg manages the website",
      privacyPolicyText3:
        "This page informs about our policy regarding the collection, use and disclosure of personal data when using our Service and about options for actions related to this data.",
      privacyPolicyText4:
        "Эта страница информирует о нашей политике в отношении сбора, использования и раскрытия персональных данных при использовании наших Услуг и о вариантах действий, связанных с этими данными.",

      privacyPolicyCollectingInfo: "COLLECTION AND USE OF INFORMATION",
      privacyPolicyCollectingInfoText1:
        "We collect several different types of information for various purposes in order to provide and improve our Service for you.",
      privacyPolicyCollectingInfoText2: "Personal data",
      privacyPolicyCollectingInfoText3: `When using our Service, we may ask you to provide us with certain personal information that can be used to contact or identify you ("Personal Data"). Identification information may include, but is not limited to:`,
      privacyPolicyCollectingInfoText4: `We may also collect information about access to and use of the Service ("Usage Data"). This data may include information such as your computer's Internet protocol address (for example, IP address), browser type, browser version, pages of our Service, time of visit, time spent on these pages, unique device identifiers and other diagnostic data.`,
      privacyPolicyCollectingInfoText5:
        "We use cookies and similar technologies to track activity on our Service and store certain information.",
      privacyPolicyCollectingInfoText6:
        "Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies that are also used are tags and scripts to collect and track information, as well as to improve and analyze our Service.",
      privacyPolicyCollectingInfoText7: `You can tell your browser to refuse all cookies or specify when to send a cookie. However, if you do not accept cookies, you will not be able to use some of the functions of our Service.`,
      privacyPolicyCollectingInfoSubtitle1: "Types of data collected",
      privacyPolicyCollectingInfoSubtitle2: "Usage data",
      privacyPolicyCollectingInfoSubtitle3: "Tracking & Cookies Data",
      privacyPolicyCollectingInfoSubtitle4: "Examples of Cookies used",
      privacyPolicyCollectingInfoList1Item2: "- First and Last name",
      privacyPolicyCollectingInfoList1Item3: "- Phone number",
      privacyPolicyCollectingInfoList1Item4:
        "- Address, City, Country, Postal Code",
      privacyPolicyCollectingInfoList1Item5: "- Cookies and Usage data",

      privacyPolicyCollectingInfoList1Item1:
        "- Session Cookies. We use session Cookies to manage our Service.",
      privacyPolicyCollectingInfoList2Item2:
        "- Preference cookies. We use Preference cookies to remember your preferences and various settings.",
      privacyPolicyCollectingInfoList3Item3:
        "- Security cookies. We use Security Cookies to ensure security.",

      privacyPolicyDataUse: "DATA USAGE",
      privacyPolicyDataUseText:
        "Netex.kg uses the collected data for various purposes:",
      privacyPolicyDataUseListItem1:
        "- Provide services and maintain the functionality of the Service",
      privacyPolicyDataUseListItem2:
        "- Notify you about changes in the Service",
      privacyPolicyDataUseListItem3:
        "- So that you can participate in the interactive features of the Service when you decide to do so",
      privacyPolicyDataUseListItem4: "- Provide customer support and service",
      privacyPolicyDataUseListItem5:
        "- Provide analysis or valuable information to improve the Service",
      privacyPolicyDataUseListItem6: "- To monitor the use of the Service",
      privacyPolicyDataUseListItem7:
        "- Detection, prevention and elimination of technical problems",

      privacyPolicyDataTransfer: "DATA TRANSFER",
      privacyPolicyDataTransferText1:
        "Your Information, including Personal Data, may be transferred to computers located outside your state or other territorial jurisdiction in which data protection laws apply, which may differ from the laws of your jurisdiction.",
      privacyPolicyDataTransferText2:
        "Your acceptance of this Privacy Policy, followed by your submission of such information, constitutes your consent to this transfer.",
      privacyPolicyDataTransferText3:
        "Netex.kg will take all necessary steps to ensure the reliable processing of your data and in accordance with this Privacy Policy, and no transfer of your personal data will be carried out unless there is proper control, including the security of your data and other personal information.",

      privacyPolicyDisclosureData: "DISCLOSURE OF DATA",
      privacyPolicyDisclosureDataSubtitle: "Legal requirements",
      privacyPolicyDisclosureDataText:
        "Netex.kg may disclose your Personal Data in the good faith belief that such action is necessary for:",
      privacyPolicyDisclosureDataListitem1:
        "- Compliance with a legal obligation",
      privacyPolicyDisclosureDataListitem2:
        "- Security and protection of rights Netex.kg",
      privacyPolicyDisclosureDataListitem3:
        "- To prevent and investigate possible violations related to the Service",
      privacyPolicyDisclosureDataListitem4:
        "- To protect the personal safety of Service users or the public",
      privacyPolicyDisclosureDataListitem5:
        "- For protection against legal liability",

      privacyPolicyDataSecurty: "DATA SECURITY",
      privacyPolicyDataSecurtyText1:
        "The security of your data is important to us, but remember that no method of data transmission over the Internet, or method of electronic storage is 100% secure. Although we strive to use commercially acceptable means of protecting your personal data, we cannot guarantee its absolute security.",

      privacyPolicyServiceSupplier: "SERVICE PROVIDERS",
      privacyPolicySubtitle: "Analytics",
      privacyPolicyServiceSupplierText1: `We may use third-party companies and individuals to provide services to our Service ("Service Providers"), provide services to the Service on our behalf, perform Services or assist us in analyzing the use of our Service.`,
      privacyPolicyServiceSupplierText2:
        "These third parties have access to your Personal Data only to perform these tasks on our behalf and are obliged not to disclose or use them for any other purposes.",
      privacyPolicyServiceSupplierText3:
        "We may use third-party service providers to monitor and analyze the use of our Service.",
      privacyPolicyServiceSupplierText4: "- Google Analytics",
      privacyPolicyServiceSupplierText5:
        "Google Analytics is a web analytics service offered by Google that tracks and reports on website traffic. Google uses the collected data to track and control the use of our Service. This data is provided to other Google services. Google may use the collected data to contextualize and personalize the advertising of its own advertising network.",
      privacyPolicyServiceSupplierText6:
        "You can opt out of making information about activity on the Service available to Google Analytics by installing the Google Analytics browser add-in for opt-out. The setting does not allow Google Analytics JavaScript (ga.js, analytics.js and dc.js) share information with Google Analytics about your activity on the site.",
      privacyPolicyServiceSupplierText7:
        "For more information about Google's privacy policies, visit the Google Privacy and Terms web page:",

      privacyPolicyLinks: "LINKS TO OTHER SITES",
      privacyPolicyLinksText: `Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be taken to a third-party website. We strongly recommend that you read the Privacy Policy of each site you visit. We do not control and are not responsible for the content, privacy policies or actions of third-party sites or services.`,
      f2aSubtitlee:
        "Used to enter the Google confirmation code when removing and changing security settings",
      privacyPolicyKids: "CHILDREN'S PRIVACY",
      privacyPolicyKidsText: `Our Service does not affect anyone under the age of 18 ("Children"). We do not knowingly collect personal information from persons under the age of 18. If you are a parent or guardian and you know that your children have provided us with Personal Data, please contact us. If we find out that we have collected Personal Data from children without parental consent, we take steps to delete this information from our servers.`,

      privacyPolicyChanges: "CHANGES TO THIS PRIVACY POLICY",
      privacyPolicyChangesText1:
        "We may update our Privacy Policy from time to time. We will notify you of any changes by posting a new Privacy Policy on this page.",
      privacyPolicyChangesText2: `We will notify you by email and/or a notification method known to our Service before the changes take effect and update the "effective date" at the top of this Privacy Policy.`,
      privacyPolicyChangesText3:
        "It is recommended to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are published on this page.",

      privacyPolicyСontacts: "CONTACT US",
      privacyPolicyСontactsText:
        "If you have any questions about this Privacy Policy, please contact us: By email:",
      refundTitle: "Refund policy",
      dashboard: "Dashboard",
      refundAssets: "Assets",
      refundExchangeService: "Exchange Service",
      refundExchange: "Exchange",
      refundApplication: "Application",
      refundTimeout: "Timeout",
      refundUnaccountedFunds: "Unaccounted Funds",
      refundRefund: "Refund",

      refundDefinition1:
        " - any electronic currencies, fiat non-cash and cash national currencies and cryptocurrencies.",
      refundDefinition2:
        " (Service) - software hosted on the Internet for Asset exchange;",
      refundDefinition3:
        " - transfer of Assets between the accounts of the Service and the User; User - any person who used the services of the Service, sent Assets to the accounts of the Service intentionally to perform an Exchange or mistakenly;",
      refundDefinition4:
        " - the client's desire to exchange Assets, executed electronically through user interfaces on the Service's websites;",
      refundDefinition5:
        " - the time allotted to the User to transfer Assets to the accounts of the Service to perform the Exchange;",
      refundDefinition6:
        " - Assets credited to the Service's accounts without making an Application on the Service's websites; Assets credited to the Service's accounts in excess of the amounts specified in the Application; Assets credited to the Service's accounts after a Timeout.",
      refundDefinition7:
        " - actions of the Service to transfer Assets or Unaccounted funds from Service accounts to User accounts;",

      refundDefinition8:
        " - actions of the Service to cancel the Exchange in order to Return.",

      refundArticle1:
        "1. The developed policy describes the actions and inactions of the Service for the Return of Assets to Users, as well as the fees provided by the Service for processing Refunds. This policy is an integral part of the Rules for the provision of services of the Service;",
      refundArticle2:
        "2. The refund can only be made for an amount not exceeding the amount of the Asset sent by the User to the accounts of the Service for Exchange;",
      refundArticle3:
        "3. The refund can be made only to the account of the User specified in the Application or from which the Service accepted the Assets, except for the cases in clause 7.4 of the Policy;",
      refundArticle4:
        "4. The Service commission for performing the Refund is deducted from the amount of the Asset returned to the user;",

      refundArticle5: "5. Responsibilities of the Service:",
      refundArticle5Text1:
        "5.1. The Service undertakes to return the Assets that were sent by the User to the Service accounts only if the Exchange did not take place. The User has not received Assets from the Service to their accounts;",
      refundArticle5Text2:
        "5.2. The Service undertakes to return Unaccounted funds to the User;",

      refundArticle6: "6. The Service has the right to:",
      refundArticle6Text1:
        "6.1. Not to return Assets or Unaccounted Funds before the User requests a Refund. Any actions of the Service to Return Assets in accordance with the current policy begin after the User contacts support. This means that without the User's request, the Service is inactive regarding the Refund;",
      refundArticle6Text2:
        "6.2. Not to reimburse the User for the commission of payment systems when returning;",
      refundArticle6Text3:
        "6.3. To refuse the Refund procedure to the User, except for the cases provided for in clause 7.3 of the Policy;",
      refundArticle6Text4:
        "6.4. To charge a commission for the Return of Unaccounted Funds specified in clause No. 8",
      refundArticle6Text5:
        "6.5. Charge a commission for the Refund specified in clause No. 8 in case of inability to perform an Exchange for reasons beyond the control of the Service, in particular when:",
      refundArticle6Text6:
        "The Service is unable to complete the Exchange for a blocked or limited User account; the Service cannot complete the Exchange for an Application made by the User in violation of the rules;",
      refundArticle6Text7:
        "6.6. Charge a Refund fee specified in clause No. 8 for Applications canceled by Timeout. The User has sent Assets to the Service accounts after the cancellation of the Application and requires a Refund;",
      refundArticle6Text8:
        "6.7. In accordance with clause 7.2 of this Policy, do not pay additional commissions to the User;",

      refundArticle7: "7. Special conditions:",
      refundArticle7Text1:
        "7.1. The Service stores the User's Assets to be returned free of charge;",
      refundArticle7Text2:
        "7.2. The Service does not dispose of the User's Assets subject to Return in commercial interests, does not extract investment or any other profit from such Assets in any way;",
      refundArticle7Text3:
        "7.3. The Service may make concessions and make a Refund in the event of a User error, namely, when the User made an Exchange to an account inaccessible to him, for example, to someone else's account;",
      refundArticle7Text4:
        "To make a Refund, the User must independently take care of the return of the mistakenly transferred Asset to the Service account, agree with the account holder or the payment system about the refund.",
      refundArticle7Text5:
        "The Service starts processing the user's Refund under the conditions described in the current policy and only after receiving the full amount of the mistakenly transferred Asset.",
      refundArticle7Text6:
        "For the Return of Assets, the Service withholds the commission specified in clause No. 8 of the policy;",
      refundArticle7Text7:
        "7.4. The Service may make concessions and make a Refund to the User's account other than the one specified in the Application, if it is required to return the cryptocurrency Asset.",

      quickAccess: "Quick access",

      setting: "Settings",
      ProfileSettings: "Profile settings",
      VerificationAccount: "Verification of account",
      VerificationAccountt: "Verification of account",
      Security: "Security",
      GoBack: "Go back",
      further: "further",
      SuccessfulLogin: "Successful login in",
      unactivatedMessage: "Your account has not been activated yet!",
      successfullyLoginUpMessage: "You successful login up",
      buyKokoswap: "Buy Kokoswap now",
      noFoundMessage: "No results were found for your search.",
      close: "Close",
      fundsReceivedSuccessMessage: "Funds received successfully",
      ok: "Ok",
      invalidProtectionCode: "Invalid protection code",
      dealSuccessfullyCreated: "Deal successfully created",
      successfullySent: "Successfully sent",
      newDealPreview: "New Deal (Preview)",
      serviceSearch: "Service search...",
      successfullyChanged: "Successfully changed",
      confirmToDeleteMessage: "Are you sure you want to delete the photo?",
      cancel: "Cancel",
      yesDelete: "Yes delete",
      paymentWasSuccessful: "Оплата прошла успешно",
      sendForVerification: "Send for verification",
      attentionWithdraw: "Attention! Withdraw",
      isPossibleOnlyIn: "is possible only in",
      inTotal: "in total",
      total: "Total",
      successfullyJoiningTheDeal: "Successfully joining the deal",
      transactionCompletedSuccessfully:
        "The transaction completed successfully",
      reset: "pay again",
      limit: "Limit",
      stopLimit: "Stop-Limit",
      tickerOrPair: "Ticker or pair",
      generateNew: "Generate a new",
      currentRate: "Current rate",
      rateFor24: "The course in 24 hours.",
      warning:
        "The amount in the 'I Receive' field is calculated when you fill in the 'I Give' field. Refresh the page and re-enter the amount if you are on the exchange page for a long time.",
      IGive: "I Give",
      IGet: "I Get",
      spot: "Spot",
      quickExchange: "Quick exchange",
      exchangeCryptocurrency: "Обмен криптовалют",
      trade: "Trade",
      OCO: "OCO",
      t1: `From $250 ths`,
      t1_2: "Trading volume per 24 hours",
      t2: `From 0.5%`,
      t2_2: "Lowest transaction fees",
      t3: "Over 8760 ",
      t3_2: "Registered users",
      t4: "Cryptocurrency listings",
      promo: "Promo course for",
      tmain: "Catch the profit! Buy USDT up to 30,000 soms",
      tsub: "at a special promotional rate every day. Only on Netex!",
    },
  },

  ru: {
    translation: {
      tsub: "по специальному промо-тарифу каждый день. Только на Netex!",
      tmain: "Лови выгоду! Покупай USDT до 30 000 сом",
      t1: `От $250 тыс`,
      t1_2: "Объем торгов за 24 часа",
      t2: `От 0.5%`,
      t2_2: "Самые низкие комиссии за транзакции",
      t3: "Более 8760",
      t3_2: "Зарегистрированных пользователей ",
      t4: "Листингов криптовалют",
      promo: "Промо курс на",
      Phone: "Номер",
      incorrect: "Неверно",
      fio: "ФИО",
      connect: "Подключить",
      legal: "Юр. Лица",
      companyName: "Название организации",
      futures: "Фьючерсы",
      templates: "Шаблоны платежей",
      createTemplate: "Создать шаблон",
      nameOfService: "Наименование услуги",
      tryAgain: "Попробовать снова",
      partners: "Наши партнеры",
      forBank:
        "данный ID необходимо указать в назначении платежа при банковском переводе",
      MainHeroTitle: "Простой и легкий доступ к криптовалютам",
      MainHeroTitle2: "Оплачивай коммунальные услуги",
      MainHeroDescription2: "Выводи криптовалюту на банковскую карту",
      MainHeroDescription: "Быстрый и безопасный способ покупки или обмена ",
      MainHeroDescription3:
        "Совершай сделки и обменивай криптовалюту с другими пользователями с самой низкой комиссией",
      MainHeroTitle4: "NETEX LAND - это твой путь в мир криптовалюты",
      MainHeroDescription4:
        "Зарегистрируй свой кошелек, пополни баланс и начинай открывать кейсы",
      SatisfiedClients: "100+ Довольных клиентов",
      BuyCurrencies: "Купить валюту",
      SliderButton2: "Оплатить услуги",
      p2pButon1: "Создать сделку",
      p2pButon2: "Все сделки",
      bankCard: "Банковская карта",
      mapLocation: "Расположение на карте",
      allNewsTitle: "Все новости компании",
      NetexLandButton: "Перейти на сайт",
      QuestionButton: "Как это работает?",
      ViewAllCourses: "Посмотреть все курсы",
      benefits1: "Лучшие курсы по рынку",
      benefits2: "Прозрачная комиссия от 0.5%",
      benefits3: "5-30 минутные транзакции",
      benefits4: "Высокие лимиты по обменам",
      benefits5: "Чат-поддержка онлайн 24/7",
      ReadReviews: "Читать отзывы клиентов",
      Reviews: "Отзывы",
      leaveReview: "Оставить отзыв",
      BuyDescription:
        "Все легко и просто! Укажите валюту, которую вам необходимо получить, затем введите сумму для обмена, после нажмите кнопку «Купить». Вам будет показан текущий курс и резерв нашего обменного пункта, а также появятся поля, которые необходимо будет заполнить: номера кошельков, карт и e-mail.",
      Currency: "Валюта",
      SelectCurrencyDeal: "Выбрать валюту",
      USDT: "Сумма в USDT",
      SelectCurrency: "Выберите валюту, которую хотите купить...",
      BuyBankCard: "Купить валюту банковской картой",
      WeAccept: "Мы принимаем:",
      EnterAmount: "Введите сумму...",
      StaticTitle: "Статистика за 24 часа",
      Static1: "Совершенных транзакций",
      Static2: "Всего открытых счетов",
      TermsOfUseButton:
        "Нажимая на кнопку «Регистрация», Вы принимаете условия ",
      TermsOfUse: "Публичной оферты.",
      Static3: "Оборот за сутки",
      Static4: "Резервы",
      App: "Приложение Netex App",
      AppText: "Всегда под рукой. Готово к скачиванию.",
      NetexText: "Приём платежей через криптовалюты",
      LinkToSite: "Перейти на сайт",
      NewsTitle: "Netex Новости",
      ReadNews: "Читать все новости",
      About: "О сервисе",
      NavHowStarted: "Как начать?",
      Contact: "Контакты",
      News: "Новости",
      f2a: "Двухфакторная аутентификация",
      LogIn: "Войти",
      Registration: "Регистрация",
      disable: "Отключить",
      success: "Успешно подключено",
      coinTitle: "Название",
      price: "Цена",
      dynamic: "Динамика",
      market: "Рынок",
      personalAccount: "Персональный аккаунт",
      personalData: "Персональные данные",
      LogInTitle: "Войти в аккаунт",
      LogInDontAccount: "  Ещё нет аккаунта?",
      SignUp: "Зарегистрироваться",
      Password: "Пароль",
      ForgotPassword: "Забыл(-а) пароль?",
      order: "Активные ордера",
      historyOrder: "История ордеров",
      orders: "Ордеры",
      SignInTo: "Войти в аккаунт",
      CreateAccount: "Создать аккаунт",
      HaveAnAccount: "Уже есть аккаунт?",
      ResetTitle: "Восстановить аккаунт",
      ResetDesc: "Мы отправим Вам на почту ссылку на изменение пароля",

      reset: "Оплатить еще раз",

      ActivationAuthentication: "Активация аккаунта",
      EnterActivationAuthentication: "Введите код для 2FA аутентификации",
      ActivationCode: "Введите код",
      EnterActivationCode: "Введите код активации",
      LabelEmail: "Введите Ваш e-mail",
      LabelPassword: "Придумайте пароль",
      LabelNewPassword: "Введите новый пароль",
      LabelPasswordYou: "Введите пароль",
      LabelRepeatNewPassword: "Повторите новый пароль",
      LabelPhone: "Введите номер телефона",
      LabelRepeatPassword: "Повторите пароль",
      LabelOldPassword: "Введите старый пароль",
      LabelName: "Введите имя",
      activationDescr:
        "На Ваш email было отправлено письмо с кодом подтверждения, введите его для активации аккаунта.",
      LabelLastName: "Введите фамилию",
      LabelSecurityCode: "Введите защитный код",
      Login: "Логин",
      Name: "Имя",
      id: "Хэш транзакции",
      explorer: "Эксплорер",
      LastName: "Фамилия",
      OldPassword: "Старый пароль",
      NewPassword: "Новый пароль",
      SecurityCode: "Защитный код",
      FullName: "Фамилия, Имя, Отчество",
      DateOfBirth: "Дата рождения",
      PassportNumber: "Номер паспорта",
      TIN: "ИНН",
      AddressOfResidence: "Адрес проживания",
      paymentCheck: "Квитанция о платеже",
      OurAddress: "Наш адрес",
      addressInfo: "Игембердиева 1А Бизнес центр Аврора 8 этаж 801 офис ",
      KyrgyzRepublic: "(Кыргызская республика, город Бишкек 720005)",
      City: "Город",
      Address: "Адрес",
      IdentityDocument: "Документ, удостоверяющий личность",
      ResidenceAddress: "Фото с паспортом в руках",
      LabelLogin: "Придумайте логин",
      SelectFile: "Выбрать файл",
      rate: "Оценка",

      Country: "Страна",
      ChooseCountry: "Выбрать страну",
      PhoneNumber: "Номер телефона",
      of: "из",
      Save: "Сохранить",
      NotSpecified: "Не указан",
      LogOut: "Выйти",
      select: "выбрать",
      quantity: "Количество",
      Aboutp1:
        "Netex - Ваш персональный обменный сервис. Netex позволяет совершать обмены электронных валют в огромное количество направлений. Совершать обмены с Netex можно с любого устройства: мобильный телефон, планшет или компьютер.",
      Aboutp2:
        "Netex - система, созданная на базе современного программного обеспечения и содержащая весь набор необходимых функций для удобной и безопасной конвертации наиболее распространенных видов электронных денег.",
      Aboutp3:
        "Наш обменник электронных валют создан для тех, кто хочет быстро, безопасно и по выгодному курсу обменять такие виды электронных валют как: Perfect Money, Payeer, AdvCash, Qiwi, Yandex, криптовалюты Bitcoin, Bitcoin Cash, Ethereum, Litecoin и другие, служба поддержки. Кроме того с консультантами можно связаться по телефону.",
      Aboutp5:
        "Репутация превыше всего! Главное кредо сервиса - абсолютная прозрачность и честность. Вы можете быть уверены в безопасности своих средств, производя обмен в Netex.",
      FAQTitle: "Часто задаваемые вопросы (FAQ)",

      SendLink: "Отправить ссылку",
      YourBalance: "Ваш баланс:",
      Settings: "Настройки",
      moneyTransaction: "Перевод средств",
      sendFunds: "Отправить средства",
      sendFundsDescr: "Отправляйте средства другим пользователям Netex",
      getFunds: "Получить средства",
      getFundsDescr: "Получайте средства от других пользователей Netex",
      showAllFunds: "Все полученные средства",
      userLogin: "Email пользователя",
      enterUserLogin: "Введите email пользователя",
      currencyAmount: "Валюта и сумма отправления",
      protectionCode: "Код протекции",
      enterProtectionCode: "Код протекции",
      PaymentSystemShort: "Платеж.система",
      availableAmount: "Доступная 'сумма':  ",
      continue: "Продолжить",
      getFundsSubtitle:
        "На Ваш счет пришел запрос на отправку средств, введите код протекции, чтобы получить средства",

      allFundsReceived: "Все полученные средства",
      newFunds: "Новые",
      oldFunds: "Старые",
      fromWhom: "От кого",
      amount: "Сумма",
      acceptFunds: "Принять",
      acceptedFunds: "Принято",

      operationHistory: "История операции",
      ThePeriodFrom: "Период с",
      PeriodTo: "Период до",
      allOperations: "Все операции",
      topUping: "Пополнение",
      incomes: "Приход",
      expenses: "Расход",
      state: "Статус",

      NetworkCommission: "Комиссия сети",

      PaymentDetailsWarningText:
        "Пожалуйста, будьте внимательны при вводе своих платежных реквизитов.",
      VerificationWarningTextp1:
        "Идентификация счета необходима для предотвращения случаев мошенничества, противодействия отмыванию средств и финансирования терроризма.",
      VerificationWarningTextp2:
        "- В поле «документ, удостоверяющий личность» загрузите сканированную копию или фото внутреннего или загран паспорта, водительское удостоверение, военный билет.",
      VerificationWarningTextp3:
        "-  В поле «Фото с паспортом в руках» загрузите портрет , где в руках вы держите паспорт с лицевой стороны",
      VerificationWarningTextp4:
        "Пожалуйста, следуйте инструкциям виджета верификации.",
      PersonalData: "Персональные данные",
      SecurityTitle: "Двухфакторная авторизация",
      onlyLatine: "только латинские буквы, цифры",
      auth: "Авторизация",
      cashout: "Вывод средств",
      transfer: "Внутренний перевод",
      SecurityText: "Это дополнительный уровень защиты для Вашего аккаунта.",
      SecurityDescription:
        "Загрузите приложение Google Authenticator с Play Market или App Store. Далее, в мобильном приложении отсканируйте этот QR-код. Если Ваше мобильное приложение не поддерживает QR-коды, введите следующий код:",
      YourWallets: "Ваши кошельки",
      verification: "Не верифицирован",
      Completed: "Завершено",
      limits: "Лимиты по льготному курсу",
      replenishment: "Пополнение",
      sale: "Продажа",
      purchase: "Покупка",

      stop: "Стоп-цена",

      main: "Главная",
      topUp: "Пополнить",
      withdraw: "Вывести",
      transactions: "Переводы",
      operations: "Операции",
      exchangeRates: "Курсы валют",
      buyEMoney: "Купить криптовалюту",
      sellEMoney: "Продать криптовалюту",
      withdrawal: "Вывод",
      TopUpBalance: "Пополнить баланс",

      Withdraw: "Вывод",
      deposit: "Ввод",

      phoneNumber: "Номер телефона",
      toLinkPhoneNumber: "Для привязки номера телефона пройдите ",
      email: "Электронная почта",
      enable: "Включить",
      LastEntrance: "Последний вход",
      dateOfRegistration: "Дата регистрации",
      buy: "Купить",
      payments: "Оплата услуг",
      paymentsBP: "Оплата услуг через Binance Pay",
      interiorTransfer: "Внутренний перевод",
      AccountHistory: "История аккаунта",
      date: "Дата",
      action: "Действие",
      ipAddress: "IP Адрес",
      browser: "Браузер",
      atRate: "По курсу",
      cryptocurrencies: "Криптовалюты",
      fiat: "Фиат",
      terminals: "Терминалы",
      cash: "Наличные",
      replenishmentSubtitle:
        "При пополнении баланса любым из способов, Ваша сумма платежа будет конвертирована в USDT по курсу нашего сайта валюты пополнения. Ваш баланс отображается в USDT (Tether).",
      paymentByCard: "Оплата картой",
      topUpVia: "Пополнить через",
      commissions: "Комиссия",
      enterTheAmount: "Введите сумму в",
      paymentСonfirmation: "Подтверждение платежа",
      amountToBeCredited: "Сумма к зачислению",
      f2aSubtitlee:
        "Используется для ввода кода подтверждения Google при снятии и изменении настроек безопасности",
      amountToPay: "Сумма к оплате",
      ourCommission: "Наша комиссия",
      additionalCommission: "Дополнительная комиссия",
      systemCommission: "Комиссия системы",
      note: "Примечание",
      confirm: "Подтвердить",
      depositAddress: "депозитный адрес",
      DialogCryptoSubtitle:
        "Если отправить криптовалюту на неверный адрес (например, биткойн на адрес Bitcoin Cash), криптовалюта будет потеряна",
      adress: "адрес",
      copied: "Скопировано",
      copy: "Скопировать",
      ToPayThroughTheTerminal: "Для оплаты через терминал",
      clear: " Понятно",
      ForPayment: "Для оплаты",
      YouNeedToContactUsByWhatsApp:
        "Вам необходимо связаться с нами по WhatsApp",

      DueDate: "Срок исполнения",
      Instantly: "Моментально",
      MinAmount: "Минимальная сумма",
      survey: "Опрос",
      answerTheQuestions: "Пожалуйста, ответьте на вопросы",
      OurCommission: "Наша комиссия",
      SystemFee: "Комиссия системы",
      AmountDispatch: "Сумма отправления",
      AmountToCredited: "Сумма к зачислению",
      WriteOffAmount: "Сумма к списанию",
      AmountInCurrency: "Сумма в валюте",
      Continue: "Продолжить",
      balance: "Баланс",
      WorkingTime: "Рабочее время",
      WorkingTimeInfo: "Пн-Пт 10:00-19:00",
      agreeText: "Я прочитал и согласен с указанной выше информацией ",
      warningTextP2P:
        "Чтобы не стать жертвой  мошенников, никогда не переводите криптовалюту до фактического получения оплаты! Не верьте никому кто представляется службой поддержки и убеждает Вас завершить сделку до того, как вы получили платеж - это мошенники. Как только продавец подтверждает ордер и переводит активы покупателю, сделка считается завершенной и не может быть оспорена. Netex  не несет никакой ответсвенности за сделки совершенные вне платформы.",
      inProgress: "В процессе",
      WarningParticipantSent:
        "Вы успешно присоединились к сделке переведите/пополните банковскую карту. Сохраните фото чека/скриншот перевода и нажмите кнопку отправить.",
      WarningOwnerSent:
        "Сохраните фото чека/скриншот перевода и нажмите кнопку отправить.",
      EnterYourPersonal: "Введите свои личные данные",
      Retrieved: "Получил",
      ConfirmCash:
        "Внимательно проверьте, получили ли вы оплату. После нажатия кнопки «Получил», средства будут переведены на аккаунт второго участника  безвозвратно и сделка будет завершена.",
      deleteDeal: "Вы уверены что хотите удалить.",
      participantName: "Имя участника",
      participantPhone: "Телефон участника",
      meeting: "Личная встреча",
      card2: "Банковская карта (фиат на крипту)",
      warningСheque:
        "Убедитесь, что деньги поступили на Ваш банковский счет. Если вы нажмете на кнопку (подтвердить), средства будут переведены безвозвратно.",
      bankNameLabel: "Напишите название банка ",
      bankName: "Название банка",
      Joined: "Успешно присоединено",
      Send: "Отправить",
      Join: "Присоединится",
      More: "Подробнее",
      СhequepaymentByCard: "Чек оплаты на карту",
      CreaterDeal: "Cоздатель этой сделки",
      hours: "часа",
      myDeals: "Мои сделки",
      Deals: "Сделки",
      CreateDeal: "Создать сделку",
      AmountFrom: "Сумма от",
      AmountTo: "Сумма до",
      Search: "Поиск",
      license: "Лицензия",
      SearchCoins: "Поиск монет...",
      SeeAll: "Посмотреть всё",
      seeInfo: "Посмотреть информацию",
      logoutTitle: "Вы уверены, что хотите выйти?",
      ListDeals: "Список сделок",
      Offers: "Предложений",
      ForAmount: "На сумму",
      GiveBack: "Отдадите",
      GetIt: "Получите",
      PaymentService: "Платежный сервис",
      User: "Пользователь",
      sell: "Продать",
      aboutusTXT: `Netex.kg - первый и самый надежный сервис в Кыргызстане 
для операций с криптовалютой! 

Быстрые выводы на электронные кошельки, карты и банковские счета. 
Моментальная покупка USDT через терминалы и О!Деньги!
Прямой партнер Binance - получи сомы в свой банк напрямую с биржи Binance. 

Netex позволяет покупать или продавать криптовалюты легко и удобно. 

Работай с любого устройства: мобильный телефон, планшет или компьютер.

Репутация превыше всего! Главное кредо сервиса - абсолютная прозрачность и честность. 
Вы можете быть уверены в безопасности своих средств, производя обмен в Netex.

Деятельность компании регулируется Службой Регулирования и Надзора за Финансовым Рынком 
при Министерстве Экономики и Коммерции Кыргызской Республики, 
лицензиия оператора обмена виртуальных активов №03 от 15.11.2022

тел: +996 508 24 1111
E-mail: business@netex.kg

ОсОО «Нетекс».
Регистрационный номер: 189262-3301-ООО
ИНН: 01402202010302
Юридический адрес: 720005; Кыргызская Республика, БИШКЕК, Ленинский район, ул. Суванбердиева, дом 106, кв. 22.
Фактический адрес: 720005; Кыргызская Республика, БИШКЕК, Первомайский район, ул. Игембердиева 1А, бизнес центр Аврора, 8-этаж, 801 офис.`,
      TypeDeal: "Тип сделки",
      type: "Тип",
      condition: "Условие",
      side: "Сторона",
      pair: "Пара",
      EnterTypeDeal: "Тип сделки",
      newDeal: "Новая сделка",
      deal: "Сделка",
      AmountReceived: "Сумма получения",
      EnterAmountReceived: "Введите сумму получения",
      CoinType: "Тип монеты",
      SelecCoinType: "Выбрать тип монеты",
      Yees: "Да",
      Noo: "Нет",
      ReceiptWallet: "Номер банковской карты",
      AmountShipment: "Сумма отправки (Фиат)",
      p2pСonfirmation: "Прикрепите фото чека",
      Сheque: "Чек",
      EnterAmountShipment: "Введите сумму отправки",
      Commentary: "Комментарий",
      WriteComment: "Напишите комментарий",
      Preview: "Предварительный просмотр",
      TranslationMethod: "Способ перевода",
      Cryptocurrency: "Криптовалюта",

      ContactUs: "Свяжитесь с нами",

      SiteNavigation: "Навигация по сайту",
      Commission: "Комиссия",

      bankCardNumber: "Номер банковской карты",
      AmountToBeDebited: "Сумма к списанию",

      OperationType: "Тип операции",
      DateTime: "Дата и время",
      Amount: "Сумма",
      PaymentSystem: "Платежная система",
      Props: "Реквизит",
      ReceiptNumber: "Номер квитанции",
      unavailable: "Услуга временно недоступна",
      AmountReceivable: "Сумма к получению",
      p2pDealsTitle: "P2P Сделки",
      exchange: "Обменять",
      allDeals: "Все сделки",
      updatedIn: "Обновлено в",
      howToStart: "Как начать ?",

      WithdrawFunds: "Вывод на карту",
      WithdrawFundsFrom: "Вывести средства с",
      withdrawWallet: "кошелька",
      network: "Сеть",
      number: "Номер",
      enterNumber: "Введите номер",

      sitePolicy: "Политика Сайта",
      usageTitle: "Публичная оферта",
      usageSubtitle: "Соглашение о предоставлении услуг сервисом Netex.kg",
      usageArticle1: "1. Общие положения",
      usageArticle2: "2. Термины и определения, используемые в Соглашении",
      usageArticle3: "3. Предмет Соглашения",
      usageArticle4: "4. Порядок оказания услуг Сервисом",
      usageArticle5: "5. Стоимость услуг",
      usageArticle6: "6. Обмен электронной валюты",
      usageArticle7: "7. Вступление Соглашения в силу",
      usageArticle8: "8. Ответственность Сторон",
      usageArticle9: "9. Прочие положения",

      usageArticle1Сlause1:
        "Настоящее соглашение (далее по тексту Соглашение) описывает правила и условия, на основании которых предоставляются услуги мультивалютного обменного сервиса Netex.kg и является официальной письменной публичной офертой, адресованной физическим лицам (далее по тексту Пользователь), заключить Соглашение о предоставлении услуг сервисом Netex.kg на изложенных ниже условиях. Перед тем как воспользоваться услугами сервиса Netex.kg, Пользователь обязан ознакомиться в полном объеме с условиями «Соглашения о предоставлении услуг сервисом Netex.kg». Использование услуг сервиса Netex.kg возможно только, если Пользователь принимает все условия Соглашения. Действующая версия Соглашения расположена для публичного доступа на интернет-сайте сервиса Netex.kg (www.Netex.kg).",

      usageArticle2Сlause1:
        "Сервис Netex.kg — торговая марка и коммерческое обозначение системы предоставления интернет услуг по обмену электронных валют.",
      usageArticle2Сlause2: "Интернет-сайт Сервиса – www.netex.kg.",
      usageArticle2Сlause3:
        "Пользователь — любое физическое лицо, которое желает воспользоваться услугами сервиса Netex.kg и осуществившее акцепт Соглашения в соответствии с его условиями.",
      usageArticle2Сlause4:
        "Платежная система — программный продукт, созданный третьей стороной, представляющий собой механизм реализации учета денежных и/или иных обязательств, оплату товаров и услуг в сети Интернет, а также организацию взаиморасчетов между своими пользователями.",
      usageArticle2Сlause5:
        "Электронная валюта – денежное и/или иное обязательство между разработчиком данной валюты и ее пользователем, выраженное цифровым способом. Платеж/операция — перевод электронной и/или иной валюты от плательщика к получателю.",
      usageArticle2Сlause6:
        "Клиент платежной системы – лицо, заключившее соглашение с соответствующей платежной системой на приобретение имущественных прав требования к ней, измеряемых в условных единицах, принятых в соответствующей платежной системе.",
      usageArticle2Сlause7:
        "Заявка – выражение намерения Пользователя воспользоваться одной из услуг предлагаемых Сервисом Netex.kg, путем заполнения электронной формы через Интернет-сайт Сервиса, на условиях, описанных в Соглашении и указанных в параметрах этой Заявки.",
      usageArticle2Сlause8:
        "Исходная валюта – электронная валюта, которую Пользователь желает продать или обменять.",
      usageArticle2Сlause9:
        "Исходный счет – номер кошелька или любое другое обозначения счета Пользователя в Платежной системе, с которого была отправлена Исходная валюта.",
      usageArticle2Сlause10:
        "Результирующая валюта – электронная валюта, которую Пользовать получает в результате продажи или обмена Исходной валюты.",
      usageArticle2Сlause11:
        "Результирующий счет – номер кошелька или любое другое обозначения счета Пользователя в Платежной системе, на который будет отправлена Результирующая валюта",
      usageArticle2Сlause12:
        "Резерв валюты - имеющийся в распоряжении Сервиса Netex.kg, на момент создания Заявки, объем определенной Электронной валюты.",
      usageArticle2Сlause13:
        "Обмен валюты - обмен электронной валюты одной платежной системы на электронную валюту другой платежной системы.",
      usageArticle2Сlause14:
        "Курс — стоимостное соотношение двух электронных валют при их обмене.",
      usageArticle2Сlause15:
        "Хакер — квалифицированный компьютерный взломщик, злоумышленник, специализирующийся на поиске и использовании несанкционированного доступа в вычислительные сети или другое компьютеризированное оборудование для незаконного получения информации, извлечения выгоды, нанесения ущерба.",

      usageArticle3Сlause1:
        "3.1. Предметом настоящего Соглашения является предоставление Пользователю Сервисом Netex.kg услуги по обмену электронных валют.",

      usageArticle4Сlause1:
        "4.1. Заказ услуг Сервиса Netex.kg осуществляется Пользователем путем направления Заявки через Интернет-сайт Сервиса.",
      usageArticle4Сlause2:
        "4.2. Управление процессом сделки или получение информации о ходе выполнения сделки Пользователем производятся при помощи соответствующего пользовательского интерфейса, расположенного на Интернет-сайте Сервиса.",
      usageArticle4Сlause3:
        "4.3. Сервис Netex.kg осуществляет исполнение Заявок на безотзывной основе в соответствии с условиями работы соответствующих платежных систем.",
      usageArticle4Сlause4:
        "4.5. Сервис Netex.kg не является стороной соглашения между Платежной системой и Клиентом платежной системы и ни в коем случае не несет ответственности за действия Платежной системы и ее Клиента. Права и обязанности платежной системы и ее Клиента регулируются условиями предоставления услуг соответствующих Платежных систем.",
      usageArticle4Сlause5:
        "4.6. Сервис Netex.kg не требует удостоверения в том, что отправитель и получатель средств, участвующих в Операции, является одним и тем же лицом, Сервис Netex.kg не является стороной во взаимоотношениях отправителя и получателя денежных средств или электронной валюты.",
      usageArticle4Сlause6:
        "4.7. Сервис Netex.kg не проверяет правомочность и законность владения Пользователем электронными валютами и/или денежными средствами, участвующими в конкретной Операции.",
      usageArticle4Сlause7:
        "4.8. Воспользовавшись услугами Сервиса Netex.kg, Пользователь подтверждает, что законно владеет и распоряжается денежными средствами и электронной валютой, участвующими в соответствующем Платеже.",
      usageArticle4Сlause8:
        "4.9. Пользователь обязуется самостоятельно исчислять и уплачивать все налоги, требуемые согласно налоговому законодательству места нахождения Пользователя.",
      usageArticle4Сlause9:
        "4.10. Заботясь о качестве оказываемых Пользователям услуг, Сервис Netex.kg обязуется совершать все действия, в рамках настоящего Соглашения, максимально оперативно.",

      usageArticle5Сlause1:
        "5.1. Стоимость услуг Сервиса устанавливается руководством Сервиса и публикуется на интернет-сайте Сервиса.",
      change: "Измeнить",
      usageArticle5Сlause2:
        "5.2. Сервис вправе самостоятельно изменять курсы обмена электронных валют и взимаемых комиссионных в любое время в одностороннем порядке, о чем уведомляет Пользователей сервиса предварительным размещением информации об этих изменениях на интернет-сайте Сервиса.",
      usageArticle5Сlause3:
        "5.3. В Заявке создаваемой Пользователем на интернет сайте сервиса указывается Курс, размер комиссии, взимаемый соответствующей Платежной системой, за проведение Операции, размер вознаграждения Сервиса Netex.kg, а также общая сумма перечисляемых денежных средств или электронной валюты.",
      usageArticle5Сlause4:
        "5.4. Сервис Netex.kg взимает стоимость своего вознаграждения в момент проведения соответствующей Операции. Вознаграждение Сервиса вычитается из суммы Результирующей валюты.",

      usageArticle6Сlause1:
        "6.1. Путем оформления Заявки Пользователь поручает, а Сервис Netex.kg от своего имени и за счет Пользователя, совершает действия по обмену Электронной валюты одной Платежной системы (Исходная валюта) на Электронную валюту другой Платежной системы (Результирующая валюта) выбранной Пользователем.",
      usageArticle6Сlause2:
        "6.2. Пользователь обязуется перечислить (передать) Исходную валюту, в размере указанном в Заявке, а Сервис Netex.kg, после получения соответствующей Электронной валюты, обязуется перечислить (передать) Пользователю Результирующую валюту, рассчитанную по Курсу и в соответствие с тарифами сервиса.",
      usageArticle6Сlause3:
        "6.3. Размер вознаграждения Сервиса Netex.kg отражается в Заявке и подтверждается Пользователем нажатием кнопки «Далее» на одной из страниц пользовательского интерфейса при оформление заявки.",
      usageArticle6Сlause4:
        "6.4. Обязанность Сервиса Netex.kg по перечислению (передаче) Электронной валюты Пользователю считается исполненной в момент списания Электронной валюты в соответствующей Платежной системе со счета Сервиса Netex.kg, что регистрируется в истории операций соответствующей Платежной системы.",
      usageArticle6Сlause5:
        "6.5. Сервис Netex.kg вправе отменить созданную Пользователем Заявку если средства в сумме Исходной валюты не поступили на счет сервиса в течение 20 минут.",
      usageArticle6Сlause6:
        "6.6. Сервис Netex.kg вправе приостановить операцию и удержать средства Пользователя, в целях предотвращения мошеннических и других действий, которые могут нанести финансовые и репутационные потери для Сервиса или Пользователя.",
      usageArticle6Сlause7:
        "6.7. Сервис Netex.kg вправе установить финансовые и количественные лимиты на Операции. Информация о лимитах указывается на Интернет-сайте Сервиса.",

      usageArticle7Сlause1:
        "7.1. Настоящее Соглашение считается заключенным на условиях публичной оферты, акцептуемой Пользователем в ходе подачи им Заявки.",
      usageArticle7Сlause2:
        "7.2. Публичной офертой признается отображаемая Сервисом Netex.kg информация о параметрах и условиях Заявки.",
      usageArticle7Сlause3:
        "7.3. Акцептом публичной оферты признается совершение Пользователем действий по завершению формирования Заявки, подтверждающих его намерение воспользоваться услугами Сервиса Netex.kg на условиях, описанных в настоящем Соглашениях и указанных в Заявке.",
      usageArticle7Сlause4:
        "7.4. Дата и время акцепта, а также параметры условий Заявки фиксируются Сервисом Netex.kg автоматически в момент завершения формирования заявки",
      usageArticle7Сlause5:
        "7.5. Соглашение вступает в силу с момента завершения формирования Заявки Пользователем. Пользователь имеет право отказаться от совершения операции по Заявке до оплаты Операции.",

      usageArticle8Сlause1:
        "8.1. Сервис Netex.kg несет ответственность перед Пользователем в размере, не превышающем вверенной Пользователем суммы денежных средств или электронной валюты.",
      usageArticle8Сlause2:
        "8.2. Сервис Netex.kg не отвечает за неисправности, ошибки и сбои в работе программных и/или аппаратных средств, обеспечивающих функционирование услуг Сервиса Netex.kg, возникшие по причинам, не зависящим от Сервиса Netex.kg, а также связанные с этим убытки Пользователя.",
      usageArticle8Сlause3:
        "8.3 Сервис Netex.kg оказывает услуги только по обмену, покупке и продаже электронных валют. Netex.kg ни коем образом не принимает оплату в пользу каких-либо третьих лиц, а также, запрещает обмен на кошельки/счета, которые не принадлежат Пользователю. Netex.kg не вступает ни в какие партнерские отношения, не заключает никаких договоров с получателями платежей за свои товары или услуги, и категорически против таких отношений. Netex.kg нельзя использовать как промежуточный сервис для взаиморасчетов между покупателем и продавцом (заказчиком и исполнителем и т.д.).",
      usageArticle8Сlause4:
        "8.4. Сервис Netex.kg не отвечает за убытки Пользователя, возникшие в результате неправомерных действий третьих лиц.",
      usageArticle8Сlause5:
        "8.5. Пользователь несет всю ответственность за достоверность сведений, указанных им при заполнении Заявки. В случае если Пользователь не указал или неверно указал данные, Сервис Netex.kg не отвечает за убытки Пользователя, понесенные в результате допущенной ошибки.",
      usageArticle8Сlause6:
        "8.6. Информация по Операции сохраняется в базе данных сервиса и является первоисточником, на который ориентируются Стороны соглашения в спорных ситуациях.",
      usageArticle8Сlause7:
        "8.7. Стороны освобождаются от ответственности за полное или частичное неисполнение своих обязательств по Соглашению, если таковое явилось следствием обстоятельств непреодолимой силы, возникших после вступления в силу Соглашения, в результате событий чрезвычайного характера, которые не могли быть предвидены и предотвращены разумными мерами.",
      usageArticle8Сlause8:
        "8.8. В других случаях неисполнения или ненадлежащего исполнения своих обязательств по Соглашению Стороны несут ответственность в соответствии с законодательством Кыргызской Республики с учетом условий Соглашения.",

      usageArticle9Сlause1:
        "9.1. Сервис Netex.kg вправе в одностороннем порядке вносить изменения в Соглашения путем публикации изменений на Сайте Системы. Изменения вступают в силу с момента опубликования, если иной срок вступления изменений в силу не определен дополнительно при их публикации.",
      usageArticle9Сlause2:
        "9.2. Сервис Netex.kg вправе отказать Пользователю в оформлении заявки без пояснения причин отказа.",
      usageArticle9Сlause3:
        "9.3. Сервис Netex.kg вправе в случае подозрительных действий в процессе оформления заявки Пользователем, во избежание ущерба от Хакерских атак приостанавливать выполнения таких операций до выяснения причин этих действий.",
      usageArticle9Сlause4:
        "9.4. Сервис Netex.kg вправе отказать в выполнение операции обмена, покупки и продажи электронных валют, если передача Исходной валюты на счет сервиса была произведена без оформления заявки при помощи пользовательских интерфейсов на сайте сервиса. Электронная валюта, перечисленная на счета сервиса, без оформления заявки при помощи пользовательских интерфейсов на сайте сервиса может быть возвращена Пользователю по запросу с учетом вычета комиссии Платежной системы, если таковая присутствует.",
      usageArticle9Сlause5:
        "9.5. Сервис Netex.kg вправе отправлять Пользователю на указанный e-mail информацию о состоянии процесса обмена, т.к. это является неотъемлемой частью процесса успешного завершения обмена.",
      usageArticle9Сlause6:
        "9.6. Все споры и разногласия, возникшие или могущие возникнуть из настоящего Соглашения, подлежат разрешению путем переговоров на основании письменного заявления Пользователя. Сервис Netex.kg после получения от Пользователя претензии обязан в течение 15 (пятнадцати) дней удовлетворить заявленные в претензии требования либо направить Пользователю мотивированный отказ. К ответу должны быть приложены все необходимые документы. В случае если возникший спор не будет разрешен в претензионном порядке в течение 60 (шестидесяти) дней, любая из Сторон вправе обратиться за разрешением спора в суд по месту нахождения Пользователя.",

      usageСonfirm:
        "Пользователь подтверждает, что он ознакомлен со всеми положениями настоящего Соглашения и безусловно принимает их.",
      usagePublicDate: "Соглашение опубликовано «16» декабря 2019 г.",

      privacyPolicy: "Политика конфиденциальности",
      privacyPolicyText1: "Дата вступления в 'силу': 16 декабря 2019",
      privacyPolicyText2: "Netex.kg управляет вебсайтом",
      privacyPolicyText3:
        "Эта страница информирует о нашей политике в отношении сбора, использования и раскрытия личных данных при использовании нашего Сервиса и о вариантах действий связанных с этими данными.",
      privacyPolicyText4:
        "Мы используем Ваши данные для предоставления и улучшения Сервиса. Используя Сервис, Вы соглашаетесь на сбор и использование информации в соответствии с этой политикой. Если в настоящей Политике конфиденциальности не указано иное, термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях и Положениях, доступных по ссылке",

      privacyPolicyCollectingInfo: "СБОР И ИСПОЛЬЗОВАНИЕ ИНФОРМАЦИИ",
      privacyPolicyCollectingInfoText1:
        "Мы собираем несколько различных типов информации для различных целей, чтобы предоставлять и совершенствовать наш Сервис для Вас.",
      privacyPolicyCollectingInfoText2: "Личные данные",
      privacyPolicyCollectingInfoText3:
        "При использовании нашего Сервиса мы можем попросить Вас предоставить нам определенную личную информацию, которую можно использовать для связи или идентификации Вас («Личные данные»). Идентификационная информация может включать, но не ограничивается:",
      privacyPolicyCollectingInfoText4:
        "Мы также можем собирать информацию о доступе к Сервису и его использовании («Данные об использовании»). Эти данные могут включать в себя информацию, такую 'как': адрес интернет-протокола Вашего компьютера (например, IP-адрес), тип браузера, версию браузера, страницы нашего Сервиса, время посещения, время, потраченное на эти страницы, уникальные идентификаторы устройств и другие диагностические данные.",
      privacyPolicyCollectingInfoText5:
        "Мы используем файлы cookies и аналогичные технологии для отслеживания активности на нашем Сервисе и хранения определенной информации.",
      privacyPolicyCollectingInfoText6:
        "Файлы cookies представляют собой файлы с небольшим количеством данных, которые могут включать анонимный уникальный идентификатор. Cookies-файлы отправляются в Ваш браузер с веб-сайта и хранятся на Вашем устройстве. Технологии отслеживания, которые также используются - теги и сценарии для сбора и отслеживания информации, а также для улучшения и анализа нашего Сервиса.",
      privacyPolicyCollectingInfoText7:
        "Вы можете указать Вашему браузеру отказаться от всех файлов cookie или указать, когда отправлять cookie-файл. Однако, если Вы не принимаете файлы cookies, Вы не сможете использовать некоторые функции нашего Сервиса.",
      privacyPolicyCollectingInfoSubtitle1: "Типы собираемых данных",
      privacyPolicyCollectingInfoSubtitle2: "Данные об использовании",
      privacyPolicyCollectingInfoSubtitle3: "Отслеживание & Cookies данные",
      privacyPolicyCollectingInfoSubtitle4: "Примеры используемых Cookies",
      privacyPolicyCollectingInfoList1Item2: "- Имя и Фамилия",
      privacyPolicyCollectingInfoList1Item3: "- Телефонный номер",
      privacyPolicyCollectingInfoList1Item4:
        "- Адрес, Город, Страна, Почтовый индекс",
      privacyPolicyCollectingInfoList1Item5:
        "- Cookies и Данные об использовании",

      privacyPolicyCollectingInfoList1Item1:
        "- Сессионные файлы Cookies. Мы используем сессионные Cookies для управления нашим Сервисом.",
      privacyPolicyCollectingInfoList2Item2:
        "- Cookies предпочтений. Мы используем Cookies предпочтений для запоминания Ваших предпочтений и различных настроек.",
      privacyPolicyCollectingInfoList3Item3:
        "- Cookies безопасности. Мы используем Cookies безопасности для обеспечения безопасности.",

      privacyPolicyDataUse: "ИСПОЛЬЗОВАНИЕ ДАННЫХ",
      privacyPolicyDataUseText:
        "Netex.kg использует собранные данные для различных целей:",
      privacyPolicyDataUseListItem1:
        "- Предоставлять услуги и поддерживать работоспособность Сервиса",
      privacyPolicyDataUseListItem2: "- Уведомлять Вас об изменениях в Сервисе",
      privacyPolicyDataUseListItem3:
        "- Чтобы Вы могли учавствовать в интерактивных функциях Сервиса, когда решите сделать это",
      privacyPolicyDataUseListItem4:
        "- Обеспечивать поддержку и обслуживание клиентов",
      privacyPolicyDataUseListItem5:
        "- Предоставлять анализ или ценную информацию для улучшения работы Сервиса",
      privacyPolicyDataUseListItem6: "- Для мониторинга использования Сервиса",
      privacyPolicyDataUseListItem7:
        "- Обнаружение, предотвращение и устранение технических проблем",

      privacyPolicyDataTransfer: "ПЕРЕДАЧА ДАННЫХ",
      privacyPolicyDataTransferText1:
        "Ваша информация, включая Личные данные, может быть передана на компьютеры, расположенные за пределами Вашей государственной или другой территориальной юрисдикции, в которых действуют законы о защите данных, которые могут отличаться от законов Вашей юрисдикции.",
      privacyPolicyDataTransferText2:
        "Ваше согласие с настоящей Политикой конфиденциальности, за которой следует Ваше представление такой информации, представляет Ваше согласие на эту передачу.",
      privacyPolicyDataTransferText3:
        "Netex.kg предпримет все необходимые шаги для обеспечения надежной обработки Ваших данных и в соответствии с настоящей Политикой конфиденциальности, и никакая передача Ваших персональных данных не будет осуществлена, если не будет надлежащего контроля, включая безопасность Ваших данных и другой личной информации.",

      privacyPolicyDisclosureData: "РАСКРЫТИЕ ДАННЫХ",
      privacyPolicyDisclosureDataSubtitle: "Правовые требования",
      privacyPolicyDisclosureDataText:
        "Netex.kg может раскрывать Ваши Личные данные в добросовестном убеждении, что такое действие необходимо для:",
      privacyPolicyDisclosureDataListitem1:
        "- Соблюдение юридического обязательства",
      privacyPolicyDisclosureDataListitem2:
        "- Безопасность и защита прав Netex.kg",
      privacyPolicyDisclosureDataListitem3:
        "- Для предотвращения и расследования возможных нарушений связанных с Сервисом",
      privacyPolicyDisclosureDataListitem4:
        "- Для защиты личной безопасности пользователей Сервиса или общественности",
      privacyPolicyDisclosureDataListitem5:
        "- Для защиты против юридической ответственности",

      privacyPolicyDataSecurty: "БЕЗОПАСНОСТЬ ДАННЫХ",
      privacyPolicyDataSecurtyText1:
        "Безопасность Ваших данных важна для нас, но помните, что ни один способ передачи данных через Интернет, или способ электронного хранения не защищен на 100%. Хотя мы стремимся использовать коммерчески приемлемые средства защиты Ваших персональных данных, мы не можем гарантировать его абсолютную безопасность.",

      privacyPolicyServiceSupplier: "ПОСТАВЩИКИ УСЛУГ",
      privacyPolicySubtitle: "Аналитика",
      privacyPolicyServiceSupplierText1:
        "Мы можем использовать сторонние компании и отдельных лиц для оказания услуг нашего Сервиса («Поставщики услуг»), предоставления услуг Сервиса от нашего имени, выполнения Сервисных услуг или оказания нам помощи в анализе использования нашего Сервиса.",
      privacyPolicyServiceSupplierText2:
        "Эти сторонние лица имеют доступ к Вашим Личным данным только для выполнения этих задач от нашего имени и обязаны не раскрывать или использовать их для каких-либо других целей.",
      privacyPolicyServiceSupplierText3:
        "Мы можем использовать сторонних поставщиков услуг для мониторинга и анализа использования нашего Сервиса.",
      privacyPolicyServiceSupplierText4: "- Google Analytics",
      privacyPolicyServiceSupplierText5:
        "Google Analytics - это служба веб-аналитики, предлагаемая Google, которая отслеживает и сообщает о трафике веб-сайта. Google использует собранные данные для отслеживания и контроля использования нашего Сервиса. Эти данные предоставляются другим службам Google. Google может использовать собранные данные для контекстуализации и персонализации рекламы своей собственной рекламной сети.",
      privacyPolicyServiceSupplierText6:
        "Вы можете отказаться от того, чтобы предоставить информацию об активности на Сервисе доступным для Google Analytics, установив надстройку браузера Google Analytics для отказа. Надстройка не позволяет Google Analytics JavaScript (ga.js, analytics.js и dc.js) обмениваться информацией с Google Analytics о Вашей активности на сайте.",
      privacyPolicyServiceSupplierText7:
        "Для получения дополнительной информации о правилах конфиденциальности Google посетите веб-страницу «Конфиденциальность и условия Google»:",

      privacyPolicyLinks: "ССЫЛКИ НА ДРУГИЕ САЙТЫ",
      privacyPolicyLinksText:
        "Наш Сервис может содержать ссылки на другие сайты, которые не управляются нами. Если Вы нажмете ссылку третьей стороны, Вы попадете на сайт третьей стороны. Мы настоятельно рекомендуем Вам ознакомиться с Политикой конфиденциальности каждого сайта, который Вы посещаете. Мы не контролируем и не несем ответственности за контент, политику конфиденциальности или действия сторонних сайтов или служб.",

      privacyPolicyKids: "КОНФИДЕНЦИАЛЬНОСТЬ ДЕТЕЙ",
      privacyPolicyKidsText:
        "Наш Сервис не затрагивает никого моложе 18 лет ( «Дети»). Мы сознательно не собираем личную информацию от лиц моложе 18 лет. Если Вы являетесь родителем или опекуном, и Вы знаете, что Ваши дети предоставили нам Личные данные, свяжитесь с нами. Если мы узнаем, что мы собрали Личные данные у детей без подтверждения родительского согласия, мы предпринимаем шаги для удаления этой информации с наших серверов.",

      privacyPolicyChanges: "ИЗМЕНЕНИЯ В ЭТОЙ ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ",
      privacyPolicyChangesText1:
        "Мы можем время от времени обновлять нашу Политику конфиденциальности. Мы сообщим вам о любых изменениях, опубликовав новую Политику конфиденциальности на этой странице.",
      privacyPolicyChangesText2:
        "Мы уведомим Вас по электронной почте и / или известному нашему Сервису методу уведомления до вступления в силу изменений и обновим «дату вступления в силу» в верхней части настоящей Политики конфиденциальности.",
      privacyPolicyChangesText3:
        "Рекомендуется периодически просматривать эту Политику конфиденциальности для любых изменений. Изменения в этой Политике конфиденциальности действуют, когда они публикуются на этой странице.",

      privacyPolicyСontacts: "СВЯЖИТЕСЬ С НАМИ",
      privacyPolicyСontactsText:
        "Если у вас есть какие-либо вопросы по поводу этой Политики конфиденциальности, свяжитесь с 'нами': По email:",

      refundTitle: "	Политика возвратов",
      dashboard: "Личный кабинет",
      refundAssets: "Активы",
      refundExchangeService: "Обменный сервис",
      refundExchange: "Обмен",
      refundApplication: "Заявка",
      refundTimeout: "Таймаут",
      refundUnaccountedFunds: "Неучтенные средства",
      refundRefund: "Возврат",

      refundDefinition1:
        " - любые электронные валюты, фиатные безналичные и наличные национальные валюты и криптовалюты.",
      refundDefinition2:
        " (Сервис) - программное обеспечение, размещенное в сети Интернет для обмена Активов;",
      refundDefinition3:
        " - перевод Активов между счетами Сервиса и Пользователя; Пользователь - любое лицо которое воспользовалось услугами Сервиса, отправило Активы на счета Сервиса намеренно для выполнения Обмена или ошибочно;",
      refundDefinition4:
        " - желание клиента произвести обмен Активов, оформленное в электронном виде через пользовательские интерфейсы на сайтах Сервиса;",
      refundDefinition5:
        " - время, отведенное Пользователю для перечисления Активов на счета Сервиса для выполнения Обмена;",
      refundDefinition6:
        " - Активы, зачисленные на счета Сервиса без оформления Заявки на сайтах Сервиса; Активы, зачисленные на счета Сервиса сверх сумм, указанных в Заявке; Активы, зачисленные на счета Сервиса после Таймаута.",
      refundDefinition7:
        " - действия Сервиса по переводу Активов или Неучтенных средств со счетов Сервиса на счета Пользователей;",
      refundDefinition8:
        " - действия Сервиса по отмене состоявшегося Обмена с целью Возврата.",

      refundArticle1:
        "1. Разработанная политика описывает действия и бездействия Сервиса по Возврату Активов Пользователям, а также комиссии, которые предусмотрены Сервисом за обработку Возвратов. Данная политика является неотъемлемой частью Правил оказания услуг Сервиса;",
      refundArticle2:
        "2. Возврат может быть выполнен только на сумму не превышающую сумму Актива, отправленную Пользователем на счета Сервиса для Обмена;",
      refundArticle3:
        "3. Возврат может быть выполнен только на счет Пользователя, указанного в Заявке или с которого Сервис принял Активы, за исключением случаев в п. №7.4 политики;",
      refundArticle4:
        "4. Комиссия Сервиса за выполнения Возврата удерживается из суммы возвращаемого пользователю Актива;",

      refundArticle5: "5. Обязанности Сервиса:",
      refundArticle5Text1:
        "5.1. Сервис обязуется вернуть Активы, которые были отправлены Пользователем на счета Сервиса только в том случае если Обмен не состоялся. Пользователь не получил Активы от Сервиса на свои счета;",
      refundArticle5Text2:
        "5.2. Сервис обязуется вернуть Неучтенные средства Пользователю;",

      refundArticle6: "6. Сервис вправе:",
      refundArticle6Text1:
        "6.1. Не возвращать Активы или Неучтенные средства до обращения Пользователя за Возвратом. Любые действия Сервиса по Возврату Активов в соответствие с текущей политикой начинаются после обращения Пользователя в поддержку. Это означает, что без обращения Пользователя Сервис бездействует в отношение Возврата;",
      refundArticle6Text2:
        "6.2. Не возмещать Пользователю комиссии платежных систем при Возврате;",
      refundArticle6Text3:
        "6.3. Отказать Пользователю в процедуре Refund за исключением случаев предусмотренных в п.п. №7.3 политики;",
      refundArticle6Text4:
        "6.4. Взимать комиссию за Возврат Неучтенных средств, указанную в п. №8",
      refundArticle6Text5:
        "6.5. Взимать комиссию за Возврат, указанную в п. №8 в случае невозможности выполнить Обмен по причинам не зависящим от Сервиса, в частности, когда:",
      refundArticle6Text6:
        "Сервис не в состоянии завершить Обмен на заблокированный или лимитированный счет Пользователя; Сервис не может завершить Обмен по Заявке, оформленной Пользователем в нарушение правил;",
      refundArticle6Text7:
        "6.6. Взимать комиссию за Возврат, указанную в п. №8 для Заявок отмененных по Таймауту. Пользователь отправил Активы на счета Сервиса после отмены Заявки и требует Возврата;",
      refundArticle6Text8:
        "6.7. В соответствие с п. №7.2 настоящей политики не выплачивать Пользователю дополнительных комиссий;",

      refundArticle7: "7. Особые условия:",
      refundArticle7Text1:
        "7.1. Сервис хранит Активы Пользователя подлежащие Возврату на безвозмездной основе;",
      refundArticle7Text2:
        "7.2. Сервис не распоряжается Активами Пользователя подлежащими Возврату в коммерческих интересах, никаким образом не извлекает инвестиционную или любую другую прибыль с таких Активов;",
      refundArticle7Text3:
        "7.3. Сервис может пойти на уступки и выполнить Refund в случае ошибки Пользователя, а именно, когда Пользователь выполнил Обмен на недоступный ему счет, например на чужой счет;",
      refundArticle7Text4:
        "Для выполнения Refund Пользователь самостоятельно должен позаботиться о возврате ошибочно перечисленного Актива на счет Сервиса, договориться с владельцем счета или платежной системой о возврате.",
      refundArticle7Text5:
        "Сервис начинает обрабатывать Возврат Пользователя на условиях, описанных в текущей политике и только после получения полной суммы ошибочно перечисленного Актива.",
      refundArticle7Text6:
        "За Возврат Активов Сервис удерживает комиссию, указанную в п. №8 политики;",
      refundArticle7Text7:
        "7.4. Сервис может пойти на уступки и выполнить Возврат на счет Пользователя отличный от указанного в Заявке, если требуется вернуть криптовалютный Актив.",

      quickAccess: "Быстрый доступ",
      setting: "Настройки",
      ProfileSettings: "Настройки профиля",
      VerificationAccount: "Верификация аккаунта",
      VerificationAccountt: "Верификацию аккаунта",
      Security: "Безопасность",

      GoBack: "Вернуться назад",
      further: "Далее",

      SuccessfulLogin: "Успешный вход в систему",
      unactivatedMessage: "Ваш аккаунт еще не активирован!",
      successfullyLoginUpMessage: "Вы успешно авторизовались",
      buyKokoswap: "Покупай Kokoswap прямо сейчас",
      noFoundMessage: "По вашему запросу ничего не найдено",
      close: "Закрыть",
      fundsReceivedSuccessMessage: "Средства успешно получены",
      ok: "Хорошо",
      invalidProtectionCode: "Неправильный код протекции",
      dealSuccessfullyCreated: "Сделка успешно создана",
      binancePayTitle:
        "Вы можете перейти на страницу оплаты сканировав QR-Code либо нажав на кнопку",
      successfullySent: "Успешно отправлено",
      newDealPreview: "Новая сделка (Предварительный просмотр)",
      serviceSearch: "Поиск услуг...",
      successfullyChanged: "Успешно изменено",
      confirmToDeleteMessage: "Вы уверены, что хотите удалить фотографию?",
      cancel: "Отменить",
      successDisable: "Успешно отключено",
      yesDelete: "Да удалить",
      paymentWasSuccessful: "Payment was successful",
      sendForVerification: "Отправить на проверку",
      attentionWithdraw: "Внимание! Вывод",
      isPossibleOnlyIn: "возможен только на",
      inTotal: "В итоге",
      total: "Итого",
      f2aSubtitle: "Зайдите в свой аунтефикатор и введите полученный код",
      successfullyJoiningTheDeal: "Успешное присоединение к сделки",
      transactionCompletedSuccessfully: "Торговля была успешно завершена",
      limit: "Лимит",
      stopLimit: "Стоп-Лимит",
      tickerOrPair: "Тикер или пара",
      generateNew: "Cгенерировать новый",
      currentRate: "Текущий курс",
      rateFor24: "Курс за 24 часа",
      warning:
        "Сумма в поле 'Я получаю рассчитывается в момент заполнения поля 'Я даю'. Обновите страницу и введите сумму заново, если вы долго находитесь на странице обмена.",
      IGive: "Я даю",
      IGet: "Я получаю",
      spot: "Спот",
      quickExchange: "Обмен криптовалют",
      exchangeCryptocurrency: "Обмен криптовалют",
      trade: "Торговля",
      OCO: "OCO",
      youNeedVerification:
        "Для проведения каких-либо транзакций, вам необходимо пройти верификацию",
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["ru", "en"],
    fallbackLng: "en",
    detection: {
      order: ["path", "localStorage", "htmlTag", "subdomain"],
      caches: ["localStorage"],
    },
    resources,
  });

export default i18n;
