import { Replenishment } from "../../api";

let initialState = {
  crypto: {
    open: false,
    name: "",
  },

  fiat: {
    open: false,
    isConfrirmDialogOpen: false,
    name: "",
  },

  info: {
    open: false,
    name: "",
  },
  qr: [],

  replenishmentCategories: [],

  replenishmentMethods: [],

  replenishmentData: [],

  isLoading: true,

  isLoadingMethods: true,
};

const replenishmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CRYPTO_DIALOG": {
      return {
        ...state,
        crypto: action.data,
      };
    }

    case "FIAT_DIALOG": {
      return {
        ...state,
        fiat: action.data,
      };
    }

    case "INFO_DIALOG": {
      return {
        ...state,
        info: action.data,
      };
    }

    case "REPLENISHMENT_CATEGORIES": {
      return {
        ...state,
        replenishmentCategories: action.data,
      };
    }

    case "REPLENISHMENT_METHODS": {
      return {
        ...state,
        replenishmentMethods: action.data,
        isLoadingMethods: false,
      };
    }

    case "REPLENISHMENT_DATA": {
      return {
        ...state,
        replenishmentData: action.data,
        isLoading: false,
      };
    }

    case "SET_LOADING": {
      return {
        ...state,
        isLoading: action.data,
      };
    }

    case "SET_LOADING_METHODS": {
      return {
        ...state,
        isLoadingMethods: action.data,
      };
    }

    case "SET_QR": {
      return {
        ...state,
        qr: action.data,
      };
    }

    default:
      return state;
  }
};

export const setCryptoDialog = (data) => ({
  type: "CRYPTO_DIALOG",
  data,
});

export const setFiatDialog = (data) => ({
  type: "FIAT_DIALOG",
  data,
});

export const setInfoDialog = (data) => ({
  type: "INFO_DIALOG",
  data,
});

export const setReplenishmentCategories = (data) => ({
  type: "REPLENISHMENT_CATEGORIES",
  data,
});

export const setReplenishmentMethods = (data) => ({
  type: "REPLENISHMENT_METHODS",
  data,
});

export const setReplenishmentData = (data) => ({
  type: "REPLENISHMENT_DATA",
  data,
});

export const setLoading = (data) => ({
  type: "SET_LOADING",
  data,
});

export const setLoadingMethods = (data) => ({
  type: "SET_LOADING_METHODS",
  data,
});

export const setQrObank = (data) => ({
  type: "SET_QR",
  data,
});

export const getReplenishmentCategories = () => {
  return (dispatch) => {
    Replenishment.getReplenishmentCategories().then((response) =>
      dispatch(setReplenishmentCategories(response))
    );
  };
};

export const getReplenishmentMethods = (id) => {
  return (dispatch) => {
    Replenishment.getReplenishmentMethods(id).then((response) =>
      dispatch(setReplenishmentMethods(response))
    );
  };
};

export const getReplenishmentData = (slug, data) => {
  return (dispatch) => {
    Replenishment.postReplenishmentData(slug, data).then((response) =>
      dispatch(setReplenishmentData(response))
    );
  };
};

export const getQRObank = (data) => {
  return (dispatch) => {
    Replenishment.getQRObank(data).then((response) =>
      dispatch(setQrObank(response))
    );
  };
};

export default replenishmentReducer;
